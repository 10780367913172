<template>
  <div>
    <div class="header">JSON</div>
    
    <div class="ace-container">
      <div class="ace-editor" ref="ace"></div>
    </div>

    <div class="buttons">
      <v-btn text @click="beautify()">indent all</v-btn>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'

import ace from'ace-builds/src-noconflict/ace'
import beautify from 'ace-builds/src-noconflict/ext-beautify'
import 'ace-builds/webpack-resolver'
import 'ace-builds/src-noconflict/theme-github'
import 'ace-builds/src-noconflict/mode-javascript'

export default {
  name: 'surveyjs-creator-json',
  data: () => ({
    aceEditor: null,
    invalidJson: false,
    //theme: 'ace/theme/github',
    mode: 'ace/mode/json'
  }),
  props: {
    question: Object
  },
  methods: {
    beautify() {
      beautify.beautify(this.aceEditor.session)
    }
  },
  mounted () {
    let aceEditor = ace.edit(this.$refs.ace, {
      maxLines: 20,
      minLines: 1,
      fontSize: 14,
      theme: this.theme,
      mode: this.mode,
      tabSize: 2
    })

    if (this.question.value) {
      aceEditor.setValue(JSON.stringify(this.question.value), -1)
    }

    aceEditor.session.on('change', delta => {
      try {
        this.question.value = JSON.parse(aceEditor.getValue())
        this.invalidJson = false
      } catch {
        this.invalidJson = true
      } 
    })
    
    this.aceEditor = aceEditor
  }
}
</script>

<style lang="scss" scoped>
  .buttons {
    margin-top: 5px;
    text-align: right;
  }
  
  .header {
    color: #aaa;
    padding: 0 0 3px;
  }
  
  .textarea {
    height: 200px;
  }
</style>
