<template>
  <div id="tq-pivot-table" style="flex: 3 0 0; overflow: auto; width: 100%; height: 100%; position: relative;">
    <div style="display: flex; flex-direction: column; width: 100%; height: 100%;">
      <!--<ag-grid-vue ref="agGrid" style="width: 100%; flex: 1 0 0; border-top: 1px solid rgb(221, 221, 221);"
                   row-selection="single"
                   class="ag-theme-material"
                   suppressContextMenu="true"
                   suppressCellSelection="true"
                   suppressAggFuncInHeader="true"
                   groupHideOpenParents="true"
                   groupDisplayType="custom"
                   enableRangeSelection="true"
                   suppressMultiRangeSelection="true"
                   :getRowHeight="getRowHeight"
                   :processCellForClipboard="_processCellForExport"
                   :localeTextFunc = "localeTextFunc"
                   :group-row-agg-nodes="groupRowAggNodes"
                   :components = "createComponents()"
                   :getMainMenuItems="getColumnMenuItems"
                   @grid-ready="onGridReady"
                   @cell-double-clicked="onCellDoubleClicked"
                   @sortChanged="onSortChanged"
                   multiSortKey="ctrl">
      </ag-grid-vue>-->
      <!-- AgGrid container-->
      <div
          class="ag-theme-material"
          style="width: 100%; flex: 1 0 0; border-top: 1px solid rgb(221, 221, 221);"
          ref="ag-grid"
      ></div>
      <div class="table-footer md-caption" v-if="footerText != null" >{{footerText}}</div>
    </div>
    <md-dialog :md-active.sync="exportDialogVisible">
      <md-toolbar md-elevation="0" class="md-title md-primary">{{ $t('actions.export') }}</md-toolbar>
      <md-dialog-content style="overflow: auto">
        <div class="md-subheading">{{ $t('labels.filetype') }}</div>
        <div style="margin-left: 16px;">
          <div style="display: flex; flex-direction: column;">
            <div style="display: flex; flex-direction: column; align-items:flex-start">
              <md-radio v-model="exportSettings.exportType" value="excel" style="margin-bottom: 0;">Excel</md-radio>
            </div>
            <div style="display: flex; flex-direction: column; align-items:flex-start">
              <md-radio v-model="exportSettings.exportType" value="csv" style="margin-bottom: 0;">CSV</md-radio>
              <div v-if="exportSettings.exportType==='csv'" >
                <md-field style="width:auto; margin-left: 36px; margin-bottom: 8px; margin-top: 8px;">
                  <label for="csvDecimalSeparator">{{ $t('labels.decimal_separator') }}</label>
                  <md-select v-model="exportSettings.csvDecimalSeparator" name="csvDecimalSeparator">
                    <md-option value=".">. ({{ $t('labels.decimal_separator_dot') }})</md-option>
                    <md-option value=",">, ({{ $t('labels.decimal_separator_comma') }})</md-option>
                  </md-select>
                </md-field>
              </div>
            </div>
            <div style="display: flex; flex-direction: column; align-items:flex-start">
              <md-radio v-model="exportSettings.exportType" value="clipboard" style="margin-bottom: 0;">{{ $t('labels.clipboard') }} (CSV)</md-radio>
              <div v-if="exportSettings.exportType==='clipboard'" >
                <md-field style="width:auto; margin-left: 36px; margin-bottom: 8px; margin-top: 8px;">
                  <label for="clipboardDecimalSeparator">{{ $t('labels.decimal_separator') }}</label>
                  <md-select v-model="exportSettings.csvDecimalSeparator" name="csvDecimalSeparator">
                    <md-option value=".">. ({{ $t('labels.decimal_separator_dot') }})</md-option>
                    <md-option value=",">, ({{ $t('labels.decimal_separator_comma') }})</md-option>
                  </md-select>
                </md-field>
              </div>
            </div>
            <div style="display: flex; flex-direction: column; align-items:flex-start">
              <md-radio v-model="exportSettings.exportType" value="json" style="margin-bottom: 0;">Clipboard (JSON)</md-radio>
            </div>
          </div>
        </div>
        <div>
          <div class="md-subheading" style="margin-top: 24px">{{ $t('texts.EXPORT_SCOPE') }}</div>
          <div style="margin-left: 16px; display: flex; flex-direction: column;">
            <md-radio v-model="exportSettings.onlyVisible" value="true">{{ $t('texts.EXPORT_ONLY_VISIBLE_DATA') }}</md-radio>
            <md-radio v-model="exportSettings.onlyVisible" value="false" style="margin-top: 0;">{{ $t('texts.EXPORT_ALL_DATA') }}</md-radio>
          </div>
        </div>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button @click="exportDialogVisible = false">{{ $t('actions.cancel') }}</md-button>
        <md-button class="md-primary" @click="exportDialogVisible = false; startExport();">OK</md-button>
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>


<script>

//import { AgGridVue } from "ag-grid-vue";
import { getLocale, t } from '@/i18n';
import urlService from '@/services/url';
import dateFormat from 'dateformat';
import error from '@/services/error';
import { createGrid } from "ag-grid-community"
import agGridUtil from "@/services/aggrid/util";



export default {
  label: 'PivotTable',
  components: {
    //AgGridVue
  },
  props: {
    enabled: Boolean
  },
  data: () => {
    return {
      changed: true,
      gridApi: null,
      ratios: null,
      queryResult: null,
      exportDialogVisible: false,
      exportSettings: {
        exportType: 'excel',
        onlyVisible: 'true',
        csvDecimalSeparator: null
      },
      footerText: null
    }
  },
  watch: {
    enabled(enabled) {
      if (enabled) {
        this.getData(false, false);
      }
    },
    '$store.state.analysisSettingsTabulationDirty': function(analysisSettingsTabulationDirty) {
      this.changed = analysisSettingsTabulationDirty || this.changed;
    },
    '$store.state.filterValuesChanged': function(filterValuesChanged) {
      if (filterValuesChanged) {
        this.changed = true;
      }
    },
  },
  created () {
    // Set decimal point to operating system's locale
    this.exportSettings.csvDecimalSeparator = (1.1).toLocaleString().substring(1, 2);
  },
  mounted() {
    this.createAgGrid();
  },
  beforeDestroy () {
    this.$store.commit('setAnalysisSettingsTabulationDirty', false);
  },
  methods: {
    createAgGrid() {
      const agGridElem = this.$refs['ag-grid']

      const gridOptions = {
        /*
        cacheOverflowSize: 2,
        //class: "ag-theme-balham",
        components: {
          vueMaterialDatePicker: agGridUtil.getVueMaterialDatePicker()
        },
        maxConcurrentDatasourceRequests: 2,
        multiSortKey: "ctrl",
        rowModelType: "serverSide",
        rowSelection: "multiple",
        suppressCellFocus: true,
        suppressColumnVirtualisation: true,
        suppressContextMenu: true,
        columnDefs: this.columnDefs,
        onCellClicked: this.onCellClicked,
        onDisplayedColumnsChanged: this.onDisplayedColumnsChanged,
        onGridReady: this.onGridReady,
        onSelectionChanged: this.onSelectionChanged,
        onSortChanged: this.onSortChanged,
        onRowDoubleClicked: this.onRowDoubleClicked
        */


        //class: "ag-theme-material",
        rowSelection: "single",
        suppressContextMenu: "true",
        suppressCellFocus: "true",
        suppressAggFuncInHeader: "true",
        groupHideOpenParents: "true",
        groupDisplayType: "custom",
        enableRangeSelection: "true",
        suppressMultiRangeSelection: "true",
        getRowHeight: this.getRowHeight,
        processCellForClipboard: this._processCellForExport,
        getLocaleText: this.getLocaleText,
        getGroupRowAgg: this.getGroupRowAgg,
        components: this.createComponents(),
        getMainMenuItems: this.getColumnMenuItems,
        onGridReady: this.onGridReady,
        onCellDoubleClicked: this.onCellDoubleClicked,
        onSortChanged: this.onSortChanged,
        multiSortKey: "ctrl"
      }

      this.agGrid = createGrid(agGridElem, gridOptions)
      this.gridApi = this.agGrid

      // for backward compatibility also check for results_sort_model
      let datasetSortModel = this.$store.state.analysisSettings.results_sort_model || this.$store.state.analysisSettings.datasets_sort_model

      if (datasetSortModel) {
        // allow dot syntax ("." and '.fractions.')
        datasetSortModel = datasetSortModel.map(datasetSortModelField => {
          return {
            colId: datasetSortModelField.colId.replace('.fractions.', ':').replace(/\./g, '---'),
            sort: datasetSortModelField.sort,
            sortIndex: datasetSortModelField.sortIndex
          }
        })

        this.agGrid.applyColumnState({
          state: datasetSortModel,
          defaultState: { sort: null },
        })
      }

      this.agGrid.setGridOption("serverSideDatasource", this.gridDataSource)
    },
    refresh(noCache) {
      if (this.enabled) {
        this.getData(true, noCache);
      } else {
        this.changed = true;
      }
    },
    localeTextFunc(key, defaultValue) {
      return defaultValue;
    },
    getColumnMenuItems(params) {
      let menuItems = [];
      let colDef = params.column.colDef;
      menuItems = menuItems.concat([
        {
          // autosize all columns
          name: t('texts.COLUMN_MENU_AUTOSIZE_ALL'),
          action: () => {
            this.resizeColumns('auto', 'auto');
            urlService.updateAnalysisUrl('tabulation', true);
          }
        },
        {
          // minimize all columns
          name: t('texts.COLUMN_MENU_MINIMIZE_ALL'),
          action: () => {
            this.resizeColumns('min', 'min');
            urlService.updateAnalysisUrl('tabulation', true);
          }
        }
      ]);
      if (!colDef.context._isRatiosColumn && !colDef.context._isRowVarColumn) {
        menuItems.push(
            {
              // set all columns to same width
              name: t('texts.COLUMN_MENU_ALL_SAME_SIZE'),
              action: () => {
                let columnWidth = 80;
                for (let columnState of this.gridApi.getColumnState()) {
                  if (columnState.colId === params.column.colId) {
                    columnWidth = columnState.width;
                    break;
                  }
                }
                this.resizeColumns(null, columnWidth);
                urlService.updateAnalysisUrl('tabulation', true);
              }
            }
        )
      }
      menuItems.push('separator');
      menuItems = menuItems.concat([
        {
          // expand all row groups
          name: t('texts.COLUMN_MENU_EXPAND_ALL'),
          action: () => this.gridApi.expandAll()
        },
        {
          // collapse all row groups
          name: t('texts.COLUMN_MENU_COLLAPSE_ALL'),
          action: () => this.gridApi.collapseAll()
        }
      ]);
      return menuItems;
    },
    getRowHeight() {
      if (this.ratios) {
        return this.ratios.length * 20 + 4;
      } else {
        return 45;
      }
    },
    getData: async function(forceRefresh, noCache) {
      try {
        if (!forceRefresh && !noCache && (!this.enabled || !this.changed)) {
          return;
        }
        this.$store.commit('setAnalysisSettingsTabulationDirty', false);
        this.changed = false;
        this.$store.dispatch('setLoading', true);
        await this.$store.dispatch('getSourceViewPivotResults', {noCache: noCache});
        let analysisSettings = this.$store.state.analysisSettings;
        let queryResults = this.$store.state.pivotResults;
        this.updateData(queryResults, analysisSettings);
        this.$store.dispatch('setLoading', false);
      } catch(e) {
        error.runtimeError(e)
      }
    },
    onGridReady(params) {
      this.gridApi = params.api;
      // Fill table with data
      this.getData(false, false);
    },
    startExport() {
      let exportType = this.exportSettings.exportType;
      let isFileExport = exportType === 'excel' || exportType === 'csv';
      let exportParams = {
        columnGroups: true,
        sheetName: this.$store.state.view.name,
        fileName: this.generateFileName(),
        shouldRowBeSkipped: params => {
          return this._shouldRowBeSkipped(params, isFileExport);
        },
        processCellCallback: params => {
          return this._processCellForExport(params, isFileExport);
        },
        processHeaderCallback: params => {
          return this._processHeaderForExport(params, isFileExport);
        }
      };

      // ToDo: Use exportParams.columnKeys if "Export All" is selected

      if (exportType === 'excel') {
        this.gridApi.exportDataAsExcel(exportParams);
      } else if (exportType === 'csv') {
        exportParams.suppressQuotes = true;
        exportParams.columnSeparator = '\t';
        this.gridApi.exportDataAsCsv(exportParams);
      } else if (exportType === 'json') {
        let gridOptions = {
          rowHeight: this.getRowHeight(),
          headerHeight: 36,
          groupHeaderHeight: 36,
        };
        gridOptions.columnDefs = this.gridApi.getColumnDefs();
        gridOptions.columnState =  this.gridApi.getColumnState();
        let rowData = [];
        this.gridApi.forEachNode(rowNode => rowData.push(rowNode.data));
        gridOptions.rowData = rowData;
        let jsonData = JSON.stringify(gridOptions, null, 2);
        navigator.clipboard.writeText(jsonData).then(function() {}, function(err) {
          console.error('Async: Could not copy text: ', err);
        });
      } else if (exportType === 'clipboard') {
        exportParams.suppressQuotes = true;
        exportParams.columnSeparator = '\t';
        let csvData = this.gridApi.getDataAsCsv(exportParams);
        navigator.clipboard.writeText(csvData).then(function() {}, function(err) {
          console.error('Could not copy text: ', err);
        });
      }
    },
    exportData() {
      this.exportDialogVisible = true;
    },
    generateFileName() {
      return 'Export_' + this.$store.state.view.name + '_' + dateFormat(Date.now(), "yyyymmdd");
    },
    _shouldRowBeSkipped(params) {
      let exportAll = this.exportSettings.onlyVisible === "false";
      let node = params.node;
      if (exportAll) {
        // export all nodes, but not group nodes
        if (node.group) {
          // skip all group nodes
          return true;
        }
      } else {
        // export only visible nodes
        if (node.group && node.expanded) {
          // skip if node is expanded group
          return true;
        } else {
          // skip if node is part of a closed group
          let parentNode = node.parent;
          while (parentNode && parentNode.level >= 0) {
            if (parentNode.group && !parentNode.expanded) {
              return true;
            }
            parentNode = parentNode.parent;
          }
        }
      }
      return false;
    },
    // Callback for processings cells during export
    _processHeaderForExport(params, isFileExport) {
      if (isFileExport == null) { // if copied directly with Ctrl+C
        isFileExport = false;
      }
      let exportType = isFileExport ? this.exportSettings.exportType : 'clipboard';
      let useQuotesForString = exportType !== 'excel';
      let headerName = params.column.colDef.headerName;
      if (useQuotesForString) {
        headerName = '"' + headerName + '"';
      }
      return headerName;
    },
    _processCellForExport(params, isFileExport) {
      if (isFileExport == null) { // if copied directly with Ctrl+C
        isFileExport = false;
      }
      let exportType = isFileExport ? this.exportSettings.exportType : 'clipboard';
      let csvDecimalSeparator = exportType === 'excel' ? '.' : this.exportSettings.csvDecimalSeparator;
      let useQuotesForString = exportType !== 'excel';

      let value = params.value;
      if (Array.isArray(value)) {
        // value is actual cell data
        if (value.length === 1) {
          if (typeof value[0] == 'number') {
            let valueStr = '' + value[0];
            if (csvDecimalSeparator !== '.') {
              // Replace decimal point with new decimal separator
              valueStr = valueStr.replace(/(\d+)\.(\d+)/g, '$1' + csvDecimalSeparator + '$2');
            }
            return valueStr;
          } else {
            if (useQuotesForString) {
              return '"' + value[0] + '"';
            } else {
              return value[0];
            }
          }
        } else { // multiple values per cell
          let cellValueSeparator = isFileExport ? '\r\n' : '\n';
          let valueStr = '';
          for (let i = 0; i < value.length; i++) {
            valueStr += i === 0 ? value[i] : cellValueSeparator + value[i];
          }
          if (useQuotesForString) {
            valueStr = '"' + valueStr + '"';
          }
          return valueStr;
        }
      } else {
        if (typeof value === 'string') {
          if (value.startsWith(' -> ')) {
            // Remove leading arrow for collapsed groups
            value = value.substr(4);
          } else if (value === '__Total') {
            value = t('labels.total');
          }
          if (useQuotesForString) {
            value = '"' + value + '"';
          }
        } else if (value == null) {
          // Replace "null" or "undefined" with empty string
          value = '';
        }
        return value;
      }
    },

    onSortChanged(/*event*/) {
      let gridApi = this.gridApi;
      // If sort model is set while retrieving data, we do not have to update the sort model in analysis settings or update the url
      if (!gridApi._ignoreSortChanged) {
        let analysisSettings = this.$store.state.analysisSettings;
        analysisSettings.tabulation_sort_model = gridApi.getColumnState();
        urlService.updateAnalysisUrl('tabulation', true);
      } else {
        delete gridApi._ignoreSortChanged;
      }
    },

    onCellDoubleClicked(event) {
      let row = event.node;
      let column = event.column;

      let analysisSettings = this.$store.state.analysisSettings;
      let filterCriteria = [];
      // Collect filter criteria for column variables
      let columnVars = analysisSettings.tabulation_columns;
      let columnVarIndex = columnVars.length - 1;
      while (column) {
        let colDef = column.colDef || (column.getColGroupDef && column.getColGroupDef()) || (column.originalColumnGroup && column.originalColumnGroup.colGroupDef);
        if (colDef && colDef.context && colDef.context._isRatiosColumn) {
          // Do nothing if clicked on "ratios" column
          return;
        }
        if (colDef && colDef.field && (!colDef.context || (!colDef.context._isTotalColumn && !colDef.context._isRowVarColumn))) {
          // Column is not a Total column or a row variable column
          let columnVarName = columnVars[columnVarIndex];
          let headerName = colDef.headerName;
          // Remove base value (for example "(N=205)") from end of header name if present.
          headerName = headerName.replace(/\s*\(N=\d+\)\s*/, '');
          let columnVarValues = [headerName];
          columnVarValues = this.mapVariableValues(columnVarName, columnVarValues);
          filterCriteria.push({name: columnVarName, value: columnVarValues});
        }
        columnVarIndex--;
        column = column.parent;
      }

      // Collect filter criteria for row variables
      let rowVars = analysisSettings.tabulation_rows;
      for (let i = 0; i < rowVars.length; i++) {
        let rowVarName = rowVars[i];
        let rowVarColumnField = rowVarName.replace(/\./g, '_');
        let rowValue = row.group ? row.groupData[i] : row.data[rowVarColumnField];
        if (rowValue == null) {
          break;
        } else if (rowValue !== '__Total' && rowValue !== '') {
          filterCriteria.push({name: rowVarName, value: rowValue});
        }
      }

      let cellFilter;
      if (filterCriteria.length > 0) {
        cellFilter = {};
        filterCriteria.forEach(({name, value}) => {
          cellFilter[name.replace('.fractions.', ':')] = value;
        });
      }

      this.$store.dispatch('setCellFilter', cellFilter);
      this.$store.dispatch('setAnalysisView', 'datasets');
    },

    getGroupRowAgg(params) {
      let nodes = params.nodes;
      if (nodes.length > 0) {
        return JSON.parse(JSON.stringify(nodes[nodes.length  - 1].data || nodes[nodes.length - 1].aggData));
      }
    },

    createComponents() {
      let $this = this;

      function ObjectCellRenderer() {}

      ObjectCellRenderer.prototype.init = function (params) {
        // Create DOM element to display
        let div = document.createElement('div');
        let iconSpan = document.createElement('span');
        iconSpan.innerHTML='';
        iconSpan.style.display="inline-block";
        iconSpan.style.width="26px";
        div.appendChild(iconSpan);
        let labelSpan = document.createElement('span');
        labelSpan.innerHTML='';
        div.appendChild(labelSpan);
        this.labelSpan = labelSpan;

        this.div = div;
        this.refresh(params);
      };

      ObjectCellRenderer.prototype.refresh = function(params) {
        // Set value to the DOM element
        this.labelSpan.innerHTML = params.value ? params.value : '';
      }

      ObjectCellRenderer.prototype.getGui = function () {
        return this.div;
      };

      function TotalCellRenderer() {}

      TotalCellRenderer.prototype.init = function (params) {
        this.span = document.createElement('span');
        this.span.innerHTML='';
        this.span.style.fontStyle = 'italic';
        this.span.style.marginLeft = '4px';
        this.refresh(params)
      };

      TotalCellRenderer.prototype.refresh = function() {
        // Set value to the DOM element
        this.span.innerHTML = 'Total';
      }

      TotalCellRenderer.prototype.getGui = function () {
        return this.span;
      };

      function CustomTooltip () {}
      CustomTooltip.prototype.init = function(params) {
        var div = this.div = document.createElement('div');
        div.classList.add('custom-tooltip');
        div.classList.add('md-elevation-8');

        let variablesNameMap = {};
        for (let variable of $this.$store.state.view.variables) {
          variablesNameMap[variable.name] = variable;
        }

        let innerHtml = '';

        // Create innter html als lines with variable-value pairs.
        // ToDo: This is very similar to the code in onDoubleClicked. Try to combine the code.

        let row = params.api.getDisplayedRowAtIndex(params.rowIndex);
        let column = params.column;

        let analysisSettings = $this.$store.state.analysisSettings;
        // Collect filter criteria for column variables
        let columnVars = analysisSettings.tabulation_columns;
        let columnVarIndex = columnVars.length - 1;
        while (column) {
          let colDef = column.colDef || (column.getColGroupDef && column.getColGroupDef()) || (column.originalColumnGroup && column.originalColumnGroup.colGroupDef);
          if (colDef) {
            if (colDef.context && (colDef.context._isRatiosColumn || colDef.context._isRowVarColumn)) {
              // Do nothing if clicked on "ratios" column
              return;
            }
            if (colDef.field && (!colDef.context || !colDef.context._isTotalColumn)) {
              // Column is not a Total column or a row variable column
              let columnVarName = columnVars[columnVarIndex];
              let headerName = colDef.headerName;
              // Remove base value (for example "(N=205)") from end of header name if present.
              headerName = headerName.replace(/\s*\(N=\d+\)\s*/, '');
              // Add line to beginning of string to make column variables appear in same order as in the tabulator
              innerHtml = '<p><b>' + variablesNameMap[columnVarName].label + ":</b> " + headerName + '</p>' + innerHtml;
            }
          }
          columnVarIndex--;
          column = column.parent;
        }

        // Collect filter criteria for row variables
        let rowVars = analysisSettings.tabulation_rows;
        for (let i = 0; i < rowVars.length; i++) {
          let rowVarName = rowVars[i];
          let rowVarColumnField = rowVarName.replace(/\./g, '_');
          let rowValue = row.group ? row.groupData[i] : row.data[rowVarColumnField];
          if (rowValue == null) {
            break;
          } else if (rowValue !== '__Total' && rowValue !== '') {
            innerHtml += '<p><b>' + variablesNameMap[rowVarName].label + ":</b> " + rowValue + '</p>';
          }
        }
        div.innerHTML = innerHtml;
      };

      CustomTooltip.prototype.getGui = function() {
        return this.div;
      };

      return {
        objectCellRenderer: ObjectCellRenderer,
        totalCellRenderer: TotalCellRenderer,
        customTooltip: CustomTooltip
      };

    },

    // Get the maximum width that any of the specified texts will occupy on the element
    getMaxTextWidth(element, texts) {
      let elementStyle = element == null ? null : window.getComputedStyle( element, null );
      // Prepare element to measure text
      let testElement = document.createElement('div');
      testElement.style.position = 'absolute';
      if (elementStyle == null) {
        // fallback
        testElement.style.fontSize = '12px';
        testElement.style.fontFamily = 'Roboto';
      } else {
        testElement.style.fontSize = elementStyle.getPropertyValue('font-size');
        testElement.style.fontFamily = elementStyle.getPropertyValue('font-family');
        testElement.style.fontWeight = elementStyle.getPropertyValue('font-weight');
        testElement.style.letterSpacing = elementStyle.getPropertyValue('letter-spacing');
        testElement.style.textSizeAdjust = elementStyle.getPropertyValue('text-size-adjust');
      }
      document.body.appendChild(testElement);
      // Break texts into lines. Eliminate duplicate texts
      let textLines = [];
      let prevText = null;
      for (let text of texts) {
        if (text && text !== prevText) {
          textLines = textLines.concat(text.split('\n'));
        }
        prevText = text;
      }
      // Sort texts by length from longest to shortest.
      textLines.sort((a, b) => b.length - b.length);
      // Determine mWidth (width of "M" character)
      testElement.innerHTML = 'MMMMMMMM';
      let mWidth = testElement.clientWidth / 8;
      // Iterate through text lines
      let maxTextWidth = 0;
      for (let textLine of textLines) {
        // If length of text multiplied with mWidth is equal or less than maximum width, stop iteration. In this case we
        // cannot encounter a wider text, even if it consists only of "M" characters.
        if (textLine.length * mWidth < maxTextWidth) {
          break;
        }
        // replace angled bracket, because otherwise the text may be regarded as a tag
        textLine = textLine.replace('<', '&lt;').replace('>', '&gt;');
        // Set text to DOM element and measure width
        testElement.innerHTML = textLine;
        maxTextWidth = Math.max(maxTextWidth, testElement.clientWidth);
      }
      // Cleanup
      document.body.removeChild(testElement);
      return maxTextWidth;
    },

    // Determine column width for "minimize All Columns".
    getMinimumColumnWidth(colDef) {
      if (colDef.context._isRowVarColumn) {
        return 80;
      } else {
        let queryResult = this.queryResult;
        let ratios = queryResult.ratios;
        let textLines = [];
        if (colDef.context._isRatiosColumn) {
          let locale = getLocale();
          let settingsModel = this.$store.state.analysisSettingsModel;
          let ratiosModel = settingsModel.find(function(el) {
            return el.name === 'ratios';
          });
          for (let ratio of ratios) {
            let ratioModel = ratiosModel.items.find(function(el) {
              return el.name === ratio.name;
            });
            let ratioLabel = this.getRatioLabel(ratio, ratioModel, locale);
            textLines.push(...ratioLabel.split('\n')); // Split label in case it contains line breaks
          }
        } else {
          let columnIndex = this.columnFieldIndexMap[colDef.field];
          for (let ratio of queryResult.ratios) {
            let dataSet = queryResult[ratio.id];
            let maxValue = 0;
            for (let rowData of dataSet) {
              let cellValue = rowData[columnIndex];
              if (cellValue > maxValue) {
                maxValue = cellValue;
              }
            }
            let maxLengthString = '0'.repeat(('' + Math.floor(maxValue)).length);
            let decimalPlaces = ratio.decimal_places || 0;
            if (decimalPlaces > 0) {
              maxLengthString += '.' + '0'.repeat(decimalPlaces);
            }
            textLines.push(maxLengthString);
          }
        }
        let extraCellSpace = 11;
        return this.getMaxTextWidth(this.$el.querySelector('.ag-body-viewport'), textLines) + extraCellSpace;
      }
    },

    getRatioLabel(ratio, ratioModel, locale) {
      let ratioLabel = (ratioModel.label_int && ratioModel.label_int[locale]) || ratioModel.label;
      if (ratio.calc_vars) {
        let variable = this.$store.state.view.variables.find(v => v.name === ratio.calc_vars);
        ratioLabel += variable ? ' (' + variable.label + ')' : '';
      }
      ratioLabel = ratioLabel.replace(/(?:<br\s*\/?>)|\n/g, ''); // Replace <br> or <br/> with newline
      return ratioLabel;
    },

    // Determine column width for "Autosize All Columns".
    getAutoColumnWidth(colDef) {
      let minWidth = 0;
      if (colDef.context._isRowVarColumn) {
        let field = colDef.showRowGroup || colDef.field;
        let texts = [];
        this.gridApi.forEachNode( function(rowNode) {
          texts.push((rowNode.data || rowNode.aggData)[field]);
        });
        let extraCellSpace = 11;
        minWidth = this.getMaxTextWidth(this.$el.querySelector('.ag-body-viewport'), texts) + extraCellSpace;
      } else {
        minWidth = this.getMinimumColumnWidth(colDef);
      }
      let extraHeaderSpace = 34;
      return Math.max(minWidth, this.getMaxTextWidth(this.$el.querySelector('.ag-header'), [colDef.headerName]) + extraHeaderSpace);
    },

    // Resize columns with specified sizing behavior (can be null, "auto", or "min")
    resizeColumns(rowVarColumnSizing, dataColumnSizing) {
      if (rowVarColumnSizing || dataColumnSizing) {
        let analysisSettings = this.$store.state.analysisSettings;
        let columnSizingSetting = analysisSettings.tabulation_column_sizing;
        if (columnSizingSetting == null) {
          columnSizingSetting = {};
          analysisSettings.tabulation_column_sizing = columnSizingSetting;
        }
        if (rowVarColumnSizing) {
          columnSizingSetting.rowVarColumns = rowVarColumnSizing;
        }
        if (dataColumnSizing) {
          columnSizingSetting.dataColumns = dataColumnSizing;
        }
      }
      let columns = this.gridApi.getColumns();
      let colDefs = columns.map(column => column.colDef);
      let columnWidths = this.getColumnWidths(colDefs);
      let columnState = this.gridApi.getColumnState();
      // ColumnState is an array. To have faster access for cases with many columns,
      // we create a map from colId to the columnState.
      let columnStateMap = new Map(columnState.map(state => [state.colId, state]));
      let columnCount = columns.length;
      for (let i = 0; i < columnCount; i++) {
        columnStateMap.get(columns[i].colId).width = columnWidths[i];
      }
      this.gridApi.setColumnState(columnState);
    },

    // Returns width in pixels for all specified column definitions.
    getColumnWidths(colDefs) {
      let analysisSettings = this.$store.state.analysisSettings;
      let columnSizingSetting = analysisSettings.tabulation_column_sizing || {};
      // row variable columns
      let rowVarColumnSizing = columnSizingSetting.rowVarColumns;
      let dataColumnSizing = columnSizingSetting.dataColumns;
      let columnWidths = [];
      for (let colDef of colDefs) {
        let width;
        if (colDef.context._isRowVarColumn) {
          if (rowVarColumnSizing === 'min') {
            width = 80;
          } else {
            width = this.getAutoColumnWidth(colDef);
            if (colDef.showRowGroup) {
              // Add pixels for expand icon
              width += 24;
            }
          }
        } else if (colDef.context._isRatiosColumn) {
          width = this.getAutoColumnWidth(colDef);
        } else {
          if (dataColumnSizing === 'min') {
            width = this.getMinimumColumnWidth(colDef);
          } else if (!isNaN(parseInt(dataColumnSizing))) {
            width = parseInt(dataColumnSizing);
          } else {
            width = this.getAutoColumnWidth(colDef);
          }
        }
        columnWidths.push(width)
      }
      return columnWidths;
    },

    mapVariableValues(variableName, variableValues) {
      /*
      Example for variables value mappings in query result:
      variables_value_mappings: [
        {
          variable: "fahrzeugdaten.berichtsquartal"
          categories: [
            {
              new: "2015_Q3-2016_Q2"
              original: ["2015_Q3", 2015_Q4", "2016_Q1", "2016_Q2"]
            },
            {
              new: "2016_Q3-2017_Q2"
              original: ["2016_Q3", 2016_Q4", "2017_Q1", "2017_Q2"]
            }
          ],
        }
      ]
      */
      let mappings = this.queryResult.variables_value_mappings;
      if (mappings) {
        for (let varMapping of mappings) {
          if (varMapping.variable === variableName) {
            let categoryMappings = varMapping.categories;
            for (let i = variableValues.length - 1; i >= 0; i--) {
              let variableValue = variableValues[i];
              for (let categoryMapping of categoryMappings) {
                if (variableValue === categoryMapping.new) {
                  Array.prototype.splice.apply(variableValues, [i, 1].concat(categoryMapping.original));
                  break;
                }
              }
            }
            break;
          }
        }
      }
      return variableValues;
    },

    updateData(queryResult, analysisSettings) {
      // console.log(JSON.stringify(queryResult, null, 2))
      this.gridApi.setGridOption('rowData', []);
      this.gridApi.setGridOption('columnDefs', []);

      let viewNotes = this.$store.state.view.notes;
      this.footerText = viewNotes ? viewNotes.tabulation_footer : null;
      this.queryResult = queryResult;

      // If there is no data on the query result, we keep the table empty
      //queryResult.columns =[]; // uncomment to fake empty data
      if (queryResult.rows == null || queryResult.rows.length === 0 ||
          queryResult.columns == null || queryResult.columns.length === 0) {
        return;
      }

      let ratios = JSON.parse(JSON.stringify(queryResult.ratios));
      this.ratios = ratios;

      let isRowGrouping = analysisSettings.tabulation_row_grouping !== 'inactive';
      // Remove Total rows except last one if grouping is not applied
      if (!isRowGrouping) {
        let  rows = queryResult.rows;
        // Collect indices of Total rows, except the last one
        let totalRowIndices = [];
        for (let rowIndex = 0; rowIndex < rows.length; rowIndex++) {
          let row = rows[rowIndex];
          for (let i = 0; i < row.length; i++) {
            if (row[i] === '__Total' && row[0] !== '__Total') {
              totalRowIndices.push(rowIndex);
              break;
            }
          }
        }
        // Remove Total rows from rows and from each ratio data set
        let rowSets = [rows].concat(ratios.map(ratio => queryResult[ratio.id]));
        for (let rowSet of rowSets) {
          for (let i = totalRowIndices.length - 1; i >= 0; i--) {
            rowSet.splice(totalRowIndices[i], 1);
          }
        }
      }

      let variablesNameMap = {};
      for (let variable of this.$store.state.view.variables) {
        variablesNameMap[variable.name] = variable;
      }

      // Create html and plain text string for ratios cell
      let locale = getLocale();
      let settingsModel = this.$store.state.analysisSettingsModel;
      let ratiosModel = settingsModel.find(function(el) {
        return el.name === 'ratios';
      });
      let ratiosNamesValue = '';
      let ratiosNamesHtml = '<div class="auto-expand" style="color: #aaa">';
      for (let i = 0; i < ratios.length; i++) {
        let ratio = ratios[i];
        if (i > 0) {
          ratiosNamesValue += '\n';
          ratiosNamesHtml += '<br>';
        }
        let ratioModel = ratiosModel.items.find(function(el) {
          //ToDo: Eher '(calrVars)' von ratio.name wegnehmen zum Vergleich
          return el.name === ratio.name;
        });
        let ratioLabel = this.getRatioLabel(ratio, ratioModel, locale);
        ratiosNamesValue += ratioLabel;
        ratiosNamesHtml += ratioLabel;
      }
      ratiosNamesHtml += '<div>';


      let formatNumber = function(number, precision) {
        if (number === '') {
          return '-'
        } else {
          number = parseFloat(number);
          return (number + (number >= 0 ? Number.EPSILON : -Number.EPSILON)).toFixed(precision || 0);
        }
      }

      // Format cell values
      let ratioNamesCellRenderer = function() {
        return ratiosNamesHtml;
      };

      // Format cell values
      let cellRenderer = function(params) {
        let value = params.getValue();
        let htmlValue = '<div class="auto-expand value-cell">';
        if (Array.isArray(value)) {
          for (let i = 0; i < value.length; i++) {
            if (i > 0) {
              htmlValue += '<br>';
            }
            htmlValue += formatNumber(value[i], analysisSettings.ratios[i] ? analysisSettings.ratios[i].decimal_places : 0);
          }
        } else {
          htmlValue += formatNumber(value);
        }
        htmlValue += '</div>';
        return htmlValue;
      };

      let rowLabelCellRenderer = function(params) {
        let row = params.node;
        if (params.value == null) {
          return '';
        }

        let htmlValue = '<div class="auto-expand">';
        if (params.value === '__Total') {
          htmlValue += '<em>Total</em>';
        } else {
          if (!row.aggData) {
            htmlValue += params.value;
          } else {
            htmlValue += '<em>Total</em>';
          }
        }
        htmlValue += '</div>';
        return htmlValue;
      };


      let rowGroupCellRendererSelector = function(params) {
        if (params.value === '__Total') {
          return {component: 'totalCellRenderer'}
        } else if (params.node.firstChild && params.node.lastChild) {
          return {component: 'objectCellRenderer' }
        } else {
          return {component: 'agGroupCellRenderer' }
        }
      };


      let rowVarComparator = function(valueA, valueB, nodeA, nodeB, isInverted) {
        // Keep rows with total values at bottom.
        // An empty string indicates a total row for a previous row variable.
        if (valueA == null || valueB == null) {
          return valueA - valueB;
        } else if (valueA === '' || /^__Total/.test(valueA)) {
          return isInverted ? -1 : 1;
        } else if (valueB === '' || /^__Total/.test(valueB)) {
          return isInverted ? 1 : -1;
        } else {
          return valueA.localeCompare(valueB);
        }
      };

      let cellComparator = function(valueA, valueB, nodeA, nodeB, isInverted) {
        if (nodeA.group || nodeB.group) {
          // Nodes are group nodes, keep original sorting order
          return (nodeA.childIndex - nodeB.childIndex) * (isInverted ? -1 : 1);
        } else {
          // Both nodes are leaf nodes, therefore we compare cell values.
          // Keep rows with total values at bottom
          if ((nodeA.data || nodeA.aggData)._isTotalRow) {
            return isInverted ? -1 : 1;
          } else if ((nodeB.data || nodeB.aggData)._isTotalRow) {
            return isInverted ? 1 : -1;
          } else {
            // If multiple ratios are displayed, start with  comparison of first ratio. If values are same,
            // contiue with second ratio, etc.          
            if (Array.isArray(valueA)) {
              for (let i = 0; i < valueA.length; i++) {
                if (valueA[i] !== valueB[i]) {
                  return valueA[i] - valueB[i];
                }
              }
              return 0;
            } else {
              return valueA - valueB;
            }
          }
        }
      };


      // Build column definitions agGrid. Columns can be multi level with a tree structure. 
      // Each column value in the json data is an array containing the column titles for each level, from top to bottom.
      // We walk through all column values and compare each level to the previous value's level. If the level 
      // differs from the previous, we start a new hierarchy from that level.
      let columnDefs = [];
      let columnLevels = []; // contains top level columns
      let columnValues = queryResult.columns;
      let columnFields = [];

      // Add columns for row variables
      let rowVariables = analysisSettings.tabulation_rows;
      let rowVarColumnFields = [];

      for (let rowVariable of rowVariables) {
        rowVarColumnFields.push(rowVariable.replace(/\./g, '_'));
      }

      // Determine maximum width for row variable columns
      // The data columns should cover at least 30% of the table width. Of the remaining 70% we subtract 80 pixels for the ratios
      // column and calculate the maximum column width by simply divinging through the number of row variables.
      let tableElement = document.getElementById('tq-pivot-table');
      let rowVarColumnMaxWidth = Math.round(Math.max(40, (tableElement.offsetWidth * 0.7 - 80) / rowVariables.length));

      for (let i = 0; i < rowVariables.length; i++) {
        let rowVariable = rowVariables[i];
        let rowColumnField = rowVarColumnFields[i];
        let colDef = {
          headerName: variablesNameMap[rowVariable].label,
          pinned: 'left',
          lockVisible: true,
          lockPinned: true,
          resizable: true,
          sortable: true,
          comparator: rowVarComparator,
          filter: 'agSetColumnFilter',
          filterParams: {selectAllOnMiniFilter: true},
          maxWidth: rowVarColumnMaxWidth,
          menuTabs:['generalMenuTab'],
          context: {
            _isRowVarColumn: true
          }
        };
        if (i < rowVariables.length - 1) {
          if (analysisSettings.tabulation_row_grouping !== 'inactive') {
            colDef.showRowGroup = rowColumnField;
            colDef.cellRendererParams = {
              suppressCount: true,
            };
            colDef.cellRendererSelector = rowGroupCellRendererSelector;
            columnDefs.push(colDef);
            // Add hidden columns for the actual data
            let hiddenColDef = {
              field: rowColumnField,
              rowGroup: true,
              hide: true,
              lockVisible: true,
              context: {}
            };
            columnDefs.push(hiddenColDef);
          } else {
            colDef.field = rowColumnField;
            colDef.cellRenderer = rowLabelCellRenderer;
            columnDefs.push(colDef);
          }
        } else {
          colDef.field = rowVarColumnFields[rowVariables.length - 1];
          colDef.cellRenderer = rowLabelCellRenderer;
          columnDefs.push(colDef);
        }
      }

      // Add column for ratio names
      columnDefs.push({
        headerName: 'Ratios',
        field: 'ratios',
        pinned: 'left',
        cellRenderer: ratioNamesCellRenderer,
        lockVisible: true,
        resizable: true,
        menuTabs:['generalMenuTab'],
        context: {
          _isRatiosColumn: true
        }
      });

      // Add root column group
      let columnVars = analysisSettings.tabulation_columns;
      let colVarsName = ''; // labels of all column vars, separated by comma
      for (let columnVarName of columnVars) {
        colVarsName += (colVarsName === '' ? '' : ', ') + variablesNameMap[columnVarName].label;
      }
      let valuesRootColumDef = {
        headerName: colVarsName,
        suppressHeaderMenuButton: true,
        marryChildren: true,
        children: [],
        menuTabs:['generalMenuTab']
      };
      columnDefs.push(valuesRootColumDef);

      let isTotalColumnHeader = function(columnValue) {
        return /^\s*__Total(?:$|\s)/.test(columnValue);
      };

      // Add columns for cell values
      columnValues.forEach(function(columnValue) {
        if (!Array.isArray(columnValue)) {
          columnValue = [columnValue];
        }
        for (let i = 0; i < columnValue.length; i++) {
          // If no column exists at the current level or if the column title differs from the one in the hierarchy, 
          // start a new hierarchy from the current level.
          if (!columnLevels[i] || columnLevels[i].headerName !== columnValue[i]) {
            columnLevels.splice(i); // delete current level and all levels below
            // create new column definition
            // All dots in the column name must be replaced by another character to prevent errors.
            let field = (i === 0 ? "" : columnLevels[i - 1].field + '$') + columnValue[i].replace(/\./g, '_');
            // Remove base count (for example " N=(243)") from column name if present
            field = field.replace(/\s*(\(N=\d+\))/, '');
            let isTotalColumn = isTotalColumnHeader(columnValue[i]);
            let isPrevTotalColumn = isTotalColumnHeader(columnValue[i - 1]);
            let headerName = (isTotalColumn && isPrevTotalColumn) ? '' : columnValue[i];
            if (isTotalColumn) {
              if (isPrevTotalColumn) {
                headerName = '';
              } else {
                headerName = headerName.replace(/^__Total/, 'Total');
              }
            }
            // If a base count exist (for example 'A205 (N=43)), insert a line break before the base count
            headerName = headerName.replace(/\s*(\(N=\d+\))/, '\n$1');
            let colDef = {
              headerName: headerName,
              field: field,
              marryChildren: true,
              tooltipField: 'ratios',
              tooltipComponent: 'customTooltip',
              menuTabs:['generalMenuTab'],
              context: {
                _isTotalColumn: isTotalColumn
              }
            };
            if (!isTotalColumn) {
              // If not a "Total" column, hide it when group is collapsed
              colDef.columnGroupShow = 'closed';
            }
            if (i === columnValue.length - 1) {
              // column has no further child columns
              colDef.resizable = true;
              colDef.cellRenderer = cellRenderer;
              colDef.sortable = true;
              colDef.comparator = cellComparator;
              columnFields.push(field);
            } else {
              // column has child columns
              colDef.children = [];
            }
            if (i === 0) {
              // column is top level column
              valuesRootColumDef.children.push(colDef);
            } else {
              // Add to children of parent column
              columnLevels[i - 1].children.push(colDef);
            }
            // add to column levels
            columnLevels.push(colDef);
          }
        }
      });

      // Create a column field to index mapping
      this.columnFieldIndexMap = {};
      for (let i = 0; i < columnFields.length; i++) {
        this.columnFieldIndexMap[columnFields[i]] = i;
      }

      // create row data
      let cellDatas = [];
      for (let ratio of ratios) {
        cellDatas.push(queryResult[ratio.id]);
      }
      // fill data
      let agGridRowData = []; // agGrid row data
      let rowCount = cellDatas[0] ? cellDatas[0].length : 0;
      for (let rowIndex = 0; rowIndex < rowCount; rowIndex++) {
        let agGridRow = {_rowIndex: rowIndex};
        // Add values for row variable columns
        let resultRows = queryResult.rows;
        let resultRow = Array.isArray(resultRows[rowIndex]) ? resultRows[rowIndex] : [resultRows[rowIndex]];
        for (let j = 0; j < resultRow.length; j++) {
          let rowVarValue = resultRow[j];
          agGridRow[rowVarColumnFields[j]] = rowVarValue;
          if (rowVarValue === '__Total') {
            agGridRow._isTotalRow = true;
            // Fill remaining row variable columns with empty values
            while (++j < resultRow.length) {
              agGridRow[rowVarColumnFields[j]] = '';
            }
          }
        }
        // Add value for ratio names (displayed value will be created by renderer)
        agGridRow.ratios = ratiosNamesValue;
        // Add values for cell values
        for (let j = 0; j < columnFields.length; j++) { // iterator over rows of dataset
          let cellValue = [];
          for (let k = 0; k < cellDatas.length; k++) {
            // ToDo: Do rounding here.
            let value = cellDatas[k][rowIndex][j];
            if (value !== '') {
              let decimalPlaces = analysisSettings.ratios[k].decimal_places || 0;
              value = parseFloat(value);
              value = (value + (value >= 0 ? Number.EPSILON : -Number.EPSILON)).toFixed(decimalPlaces);
            }
            cellValue.push(value);
          }
          if (cellValue.length === 0) {
            cellValue = 0;
          }
          agGridRow[columnFields[j]] = cellValue;
        }
        agGridRowData.push(agGridRow);
      }

      this.gridApi.setGridOption('rowData', agGridRowData);

      // Columns sizing
      let collectLeafColDefs = (colDefs) => {
        let leafColDefs = [];
        for (let colDef of colDefs) {
          if (colDef.children) {
            leafColDefs = leafColDefs.concat(collectLeafColDefs(colDef.children));
          } else {
            leafColDefs.push(colDef);
          }
        }
        return leafColDefs;
      };
      let leafColDefs = collectLeafColDefs(columnDefs);
      let leafColWidths = this.getColumnWidths(leafColDefs);
      for (let i = 0; i < leafColDefs.length; i++) {
        leafColDefs[i].width = leafColWidths[i];
      }

      // Important: Setting an empty array for column defs is a workaroung for a bug
      // in agGrid. If the don't do this, rowGroups will not be rendered propery after data update.
      this.gridApi.setGridOption('columnDefs', columnDefs);
      let sortModel = analysisSettings.tabulation_sort_model || [];
      // Remove Basis from column Id. This is necessary for old bookmarks, where columnIds may still contain the Basis.
      for (let sortColumn of sortModel) {
        if (sortColumn.colId) {
          sortColumn.colId = sortColumn.colId.replace(/\s*\(N=\d+\)\s*/, '');
        }
      }
      this.gridApi._ignoreSortChanged = true;
      this.gridApi.applyColumnState(sortModel);
      this.gridApi.resetRowHeights();
      // this.gridApi.setHeaderHeight(36); // ToDo
      // this.gridApi.setGroupHeaderHeight(36); // ToDo

      this.gridApi.expandAll();
    }
  },
}

</script>


<style lang="scss" scoped>


#tq-pivot-table {


  .table-footer {
    border-top: 1px solid #eee;
    padding: 4px 8px 2px;
    text-align: left;
  }

  :deep .ag-theme-material {

    --ag-range-selection-border-color: #cd2927;

    .ag-cell {
      width: 100%;
      text-align: right;

      .ag-icon {
        vertical-align: baseline;
      }
    }

    .custom-tooltip {
      position: absolute;
      display: flex;
      flex-direction: column;
      padding: 4px 8px;
      overflow: hidden;
      pointer-events: none;
      transition: opacity 300ms;
      background: white;
    }

    .custom-tooltip.ag-tooltip-hiding {
      opacity: 0;
    }

    .custom-tooltip p {
      margin: 5px;
      white-space: nowrap;
    }

    .ag-status-bar {
      border: none;
    }

    .ag-header {
      box-sizing: content-box;
      border-bottom: none;
    }

    .ag-header-container, .ag-pinned-left-header {
      border-bottom: solid 1px;
      border-bottom-color: var(--ag-border-color, #e2e2e2);
    }

    .auto-expand,
    .ag-header-cell-label,
    .ag-header-group-cell-label {
      text-overflow: ellipsis;
      overflow: hidden;
      padding: 0 4px;
    }

    .ag-header-group-cell-label {
      align-items: center;
    }

    .ag-header-group-text {
      height: auto;
    }

    .ag-cell,
    .ag-header-cell,
    .ag-header-group-cell {
      display: flex;
      align-items: stretch;
      line-height: 20px;
      padding: 0;
      overflow: hidden;
    }

    .ag-cell:not(.ag-cell-range-selected.ag-cell-range-right,.ag-cell-range-single-cell),
    .ag-header-cell,
    .ag-header-group-cell {
      border-right: 1px solid #ddd;
    }

    .ag-header {
      color: inherit;
    }

    .ag-header-cell,
    .ag-header-group-cell:not(.ag-header-group-cell-no-group) {
      background-color: #f8f8f8;
    }

    .ag-header-group-cell-no-group:not(:last-of-type) {
      border-right: none;
    }

    .ag-cell-value>span,
    .ag-cell-label-container {
      display: flex;
      align-items: center;
      flex: 1 0 0;
      min-width: 0;
    }

    .ag-header-cell .ag-cell-label-container {
      flex-flow: row-reverse;
      justify-content: flex-end;
      min-width: 100%;
    }

    /* Inherit background, so expanded cells can use it */
    .ag-header-container,
    .ag-header-viewport,
    .ag-pinned-left-header,
    .ag-header-row {
      background-color: inherit;
    }

    /* By default the group checkbox occupies some space due to it's left margin. */
    .ag-group-checkbox.ag-invisible {
      margin: 0;
    }

    /* Make sure expansion arrow is vertically centered and layout is balanced */
    .ag-group-expanded,
    .ag-group-contracted {
      line-height: 1;
      padding: 4px;
    }

    .ag-group-value {
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 4px;
    }

    .ag-header-cell-label {
      align-items: center;
      padding-left: 4px;
    }

    /* Allow line breaks in headers */
    .ag-header-cell-label .ag-header-cell-text {
      white-space: pre;
      width: 100%; /* Without this there are rendering problems on Firefox */
    }

    .ag-sort-ascending-icon,
    .ag-sort-descending-icon {
      margin-left: 4px;
    }

    .ag-header-icon,
    .ag-header-icon .ag-icon {
      height: 100%;
    }

    .ag-cell-label-container:not(:hover) .ag-header-cell-menu-button {
      /* Make header menu icon occupy no space if not displayed */
      width: 0;
    }

    .ag-cell-range-selected-1:not(.ag-cell-focus) {
      background-color: #FBDBDB;
    }

    .ag-cell.ag-cell-range-selected:not(.ag-cell-range-single-cell).ag-cell-range-bottom {
      border-bottom-color: #cd2927;
    }

  }

}
</style>
