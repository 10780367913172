<template>
  <div class="ace-editor-container">
    <div class="ace-editor" ref="ace-editor"></div>
    
    <v-tooltip top open-delay="300">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          class="beautify-icon"
          x-small
          outlined
          fab
          color="secondary"
          @click="beautify"
          v-if="showBeautifyButton"
        >
          <v-icon dark>
            mdi-auto-fix
          </v-icon>
        </v-btn>
      </template>
      <span>{{$t('tooltips.FORMAT_CODE')}}</span>
    </v-tooltip>
    
    
  </div>
</template>

<script>
import _ from 'lodash'

import ace from'ace-builds/src-noconflict/ace'
import 'ace-builds/webpack-resolver'
import 'ace-builds/src-noconflict/mode-javascript'
import 'ace-builds/src-noconflict/mode-html'
import 'ace-builds/src-noconflict/ext-language_tools'

import { js_beautify, html_beautify } from 'js-beautify'

let doBeautify = (value, type) => {
  if (value) {
    let beautifyOptions = { indent_size: 2 }

    if (['json', 'javascript'].indexOf(type) !== -1) {
      value = js_beautify(value, beautifyOptions)
    } else if (type === 'html') {
      value = html_beautify(value, beautifyOptions)
    }
  }

  return value
}

export default {
  name: 'surveyjs-creator-vue',
  data: () => ({
    aceEditor: null,
    showBeautifyButton: false,
    ready: false
  }),
  watch: {
    config(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.init()
      }
    }
  },
  props: {
    config: Object
  },
  methods: {
    beautify() {
      this.aceEditor.setValue(doBeautify(this.aceEditor.getValue(), this.config.type), -1)
    },
    destroy() {
      if (this.aceEditor) {
        this.aceEditor.destroy()
      }

      window.removeEventListener("resize", this.resize)
    },
    init() {
      if (this.aceEditor) {
        this.destroy()
      }

      let config = this.config

      if (!config || !this.ready) {
        return
      }

      config = Object.assign({
        aceOptions: {},
        autoResize: false,
        beautify: false,
        onChange: () => {},
        readOnly: false,
        showBeautifyButton: true,
        type: null,
        value: null
      }, config)

      let {
        aceOptions,
        autoResize,
        beautify,
        onChange,
        readOnly,
        showBeautifyButton,
        type,
        value
      } = config

      this.showBeautifyButton = showBeautifyButton

      if (autoResize) {
        window.addEventListener("resize", this.resize)
      }

      let mode = (() => {
        switch (type) {
          case 'javascript':
            return 'ace/mode/javascript'
          case 'json':
            return 'ace/mode/json'
          case 'html':
            return 'ace/mode/html'
          default:
            return null
        }
      })() 

      aceOptions = Object.assign({
        maxLines: 30,
        fontSize: 14,
        theme: null,
        mode: mode,
        showPrintMargin: false,
        tabSize: 2
      }, aceOptions || {})

      let aceEditor = ace.edit(this.$refs['ace-editor'], aceOptions)
      this.aceEditor = aceEditor

      if (readOnly) {
        aceEditor.setReadOnly(true)
      }

      if (onChange) {
        aceEditor.session.on('change', delta => {
          onChange(aceEditor.getValue())
        })
      }

      if (value) {
        if (beautify) {
          value = doBeautify(value, type)
        }
        
        aceEditor.setValue(value, -1)
      }

      if (autoResize) {
        this.resize()
      }
    },
    resize() {
      let editorElem = this.$refs['ace-editor']
      let parentNode = editorElem && editorElem.parentNode

      if (parentNode) {
        let height = parentNode.clientHeight
        let lineHeight = this.aceEditor.renderer.lineHeight

        this.aceEditor.setOptions({ maxLines: Math.floor(height / lineHeight) })
        this.aceEditor.resize()
      }
    }
  },
  mounted () {
    this.ready = true

    this.init()
  },

  beforeDestroy() {
    this.destroy()
  }
}
</script>

<style lang="scss" scoped>
  .ace-editor-container {
    position: relative;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
  }

  .ace-editor {
    position: relative;
    flex: 1 0 0;
    border-bottom: 1px solid rgb(233, 233, 233);
  }

  .beautify-icon {
    position: absolute;
    bottom: 30px;
    right: 30px;
    z-index: 1000;
  }
</style>
