<template>
  <div class="phone-call-dialer">
    <div class="call-box">    
      <div class="md-layout-item source-phone-number" style="width: 100%; margin: 0 0 0 0;">      
        <div style="width: 100%;">    
          <p style="font-size: 16px;">
            TEMA-Q: {{ this.$store.state.phoneCall.fromNumber }}
          </p>
        </div>

        <div style="width: 100%; display: flex; align-items; center; justify-content: center;">    
          <v-text-field
            v-if="showCall"
            :label="$t('texts.CALL_NUMBER')" 
            v-model="inputPhoneNumber"
            style="width: 100%; flex: 1 1 auto; margin: 0 0 20px;"
            outlined
            hide-details
          >        
          </v-text-field>
          <v-btn icon large style="flex; 0 0 auto;" class="button" @click="showDialpad"> 
            <v-icon color="grey">mdi-dialpad</v-icon>
          </v-btn>
        </div>

        <div class="dialpad" v-if="this.varShowDialpad === true">
          <div class="dialpad-row">
            <v-btn depressed class="dialpad-digit" @click="digitAction('1')">1</v-btn>
            <v-btn depressed class="dialpad-digit" @click="digitAction('2')">2</v-btn>
            <v-btn depressed class="dialpad-digit" @click="digitAction('3')">3</v-btn>
          </div>
          <div class="dialpad-row">
            <v-btn depressed class="dialpad-digit" @click="digitAction('4')">4</v-btn>
            <v-btn depressed class="dialpad-digit" @click="digitAction('5')">5</v-btn>
            <v-btn depressed class="dialpad-digit" @click="digitAction('6')">6</v-btn>
          </div>
          <div class="dialpad-row">
            <v-btn depressed class="dialpad-digit" @click="digitAction('7')">7</v-btn>
            <v-btn depressed class="dialpad-digit" @click="digitAction('8')">8</v-btn>
            <v-btn depressed class="dialpad-digit" @click="digitAction('9')">9</v-btn>
          </div>
          <div class="dialpad-row">
            <v-btn depressed class="dialpad-digit" @click="digitAction('*')">*</v-btn>
            <v-btn depressed class="dialpad-digit" @click="digitAction('0')">0</v-btn>
            <v-btn depressed class="dialpad-digit" @click="digitAction('#')">#</v-btn>
          </div>
          <div class="dialpad-row">
            <v-btn depressed class="dialpad-digit" @click="digitAction('+')">+</v-btn>
            <v-btn depressed class="dialpad-digit" @click="digitAction('<')">&lt</v-btn>
            <v-btn depressed plain disabled class="dialpad-digit" @click="digitAction('')"></v-btn>
          </div>
        </div>

        <div class="buttons" style="margin-top: 0px; text-align: right;">  
          <!-- <div style="margin: 16px; text-align: center;"> -->
          <!-- <v-btn v-if="showCall" color="grey" outlined rounded @click="call"> -->
          <v-btn class="button" v-if="showCall" @click="call" :disabled="!inputPhoneNumber">
            <v-icon color="green" style="padding-right: 5px;">mdi-phone</v-icon> {{ $t('actions.call') }}
          </v-btn>
          <v-btn class="button" v-if="showHangUp" @click="hangUp">
            <v-icon color="red" style="padding-right: 5px;">mdi-phone-hangup</v-icon> {{ $t('actions.hang_up') }}
          </v-btn>
          <v-btn class="button" v-if="showRecord" icon @click="record">
            <v-icon color="red" v-if="this.$store.state.phoneCall.recordState === ''" >mdi-record-rec</v-icon>
          </v-btn>
          <v-btn class="button" v-if="showRecord" icon @click="stopRecord">
            <v-icon color="black" v-if="this.$store.state.phoneCall.recordState === 'Aufnahme'" >mdi-stop</v-icon>
          </v-btn>
        </div>

        <v-container style="width: 100%; margin-top: 15px;">
          <v-row justify="start" no-gutters class="pa-0 ma-0">
            <v-col style="text-align: left; font-size: 18px;">
              <p>{{ callTo }}</p>
            </v-col>
            <v-col cols="2" style="text-align:center;">
              <v-icon v-if="this.$store.state.phoneCall.recordState === 'Aufnahme'" color="red">mdi-circle</v-icon>
            </v-col>
          </v-row>
          <v-row justify="start" no-gutters>
            <v-col cols="2" v-if="true || showHangUp" style="text-align:left;">
              <p style="color: blau; font-size: 18px; text-align:left;">{{ callDuration }}</p>
            </v-col>
            <v-col cols="10" >
              <p style="color: red; font-size: 18px; text-align:right;">{{ callState }}</p>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
    
    <div class="tool-box">
      <div class="phone-numbers">
        <v-list>
          <v-subheader>{{ $t('texts.PHONE_NUMBERS') }}</v-subheader>

          <div v-if="phoneNumbers.length == 0" style="text-align: center; padding: 20px 0; color: #777;">{{ $t('texts.NO_ENTRIES') }}</div>

          <v-list-item
            v-for="(phoneNumberObj, index) in phoneNumbers"          
            :value="phoneNumberObj.phoneNumber"     
            x-style="width: 100%;"
            v-bind:key="index"
          >
            <div style="display: flex; flex: 1 1 0;">
              <div style="display: flex; flex-direction: column; justify-content: center; flex: 0 0 auto; padding: 0 5px 0 0;">
                <v-btn
                  v-if="showCall"
                  color="grey-lighten-1"
                  variant="text"
                  depressed
                  large
                  icon
                  :disabled="!phoneNumberObj.phoneNumber"
                  x-style="margin-top: 7px;"
                  @click="callSelected(phoneNumberObj.phoneNumber)"
                >
                  <v-icon color="green">mdi-phone</v-icon>            
                </v-btn>
              </div>
              <div style="flex: 1 1 auto;">
                <v-list-item-content>
                  <v-list-item-title v-text="phoneNumberObj.phoneNumber"></v-list-item-title>
                  <v-list-item-subtitle v-text="phoneNumberObj.comment"> </v-list-item-subtitle>
                </v-list-item-content>  
              </div>
              <div style="display: flex; flex-direction: column; flex: 0 0 auto;">
                <v-btn small icon @click="deletePhoneNumber(phoneNumberObj)">
                  <v-icon small color="black">mdi-trash-can-outline</v-icon>
                </v-btn>
                <v-btn small icon @click="editPhoneNumber(phoneNumberObj.phoneNumber)">
                  <v-icon small color="black">mdi-square-edit-outline</v-icon>            
                </v-btn>
              </div>
            </div>
          </v-list-item>
          <div style="text-align: right; padding: 5px 15px 0;">
            <v-tooltip top open-delay="300">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  @click="addPhoneNumber"
                  fab
                  x-small
                  color="primary"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>{{$t('tooltips.ADD_NUMBER')}}</span>
            </v-tooltip>
          </div>
        </v-list>

        <v-dialog
          v-model="phoneNumberDialog"
          persistent
          width="500"
        >
          <v-card flat>
            <v-card-title class="text-h6 white--text primary">
              {{ newNumber === true ? $t('texts.NEW_NUMBER') : $t('texts.EDIT_NUMBER') }}
            </v-card-title>

            <v-card-text style="padding: 20px;">
              <v-text-field
                outlined
                :label="$t('texts.CALL_NUMBER')" 
                v-model="editedNumber"
              >        
              </v-text-field>
              <v-text-field
                :label="$t('texts.COMMENT')"  
                v-model="editedComment"
              >        
              </v-text-field>
            </v-card-text>

            <v-card-actions dense style="border-top: 1px solid rgb(233, 233, 233);"> 
              <v-spacer></v-spacer>
              <v-btn
                @click="cancelNumberEdit"
                depressed
                >
                {{ $t('actions.cancel') }}
              </v-btn>
              <v-btn
                style="text-align:right;"
                @click="saveNumber()"
                color="primary"
                >
                {{ $t('actions.save') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
      <div class="info-box">
        <div class="label">{{ $t('texts.STATUS') }}</div>
        <div class="text">{{ contactStatus }}</div>
        <div class="label" style="margin: 10px 0 0;">{{ $t('texts.COMMENT') }}</div>
        <div class="text" style="padding: 10px; border: 1px solid #ccc;">{{ comment }}</div>
        <div style="text-align: right; margin: 10px 0 0;">
          <v-dialog
            v-model="editCommentDialog"
            persistent
            width="500"
          >
            <v-card flat>
              <v-card-title class="text-h6 white--text primary">
                {{ $t('texts.EDIT_COMMENT') }}
              </v-card-title>

              <v-card-text style="padding: 20px;">
                <v-textarea
                  style="margin: 0 0 20px;"
                  :label="$t('texts.COMMENT')"
                  v-model="comment"
                  auto-grow
                  filled
                  rows="5"
                  hide-details
                ></v-textarea>
              </v-card-text>

              <v-card-actions dense style="border-top: 1px solid rgb(233, 233, 233);"> 
                <v-spacer></v-spacer>
                <v-btn
                  @click="editCommentDialog = false"
                  depressed
                  >
                  {{ $t('actions.cancel') }}
                </v-btn>
                <v-btn
                  style="text-align:right;"
                  @click="updateComment()"
                  color="primary"
                  >
                  {{ $t('actions.save') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-tooltip top open-delay="300">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                @click="editCommentDialog = true"
                fab
                x-small
                color="primary"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>{{$t('tooltips.EDIT_COMMENT')}}</span>
          </v-tooltip>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import _ from 'lodash'
  
  import confirmDialog from '@/services/confirmDialog'

  const getData = () => {
    return {
      comment: null,
      editCommentDialog: false,
      phoneNumberDialog: false,
      phoneNumbers: [],
      selectedPhoneNumber: null,
      inputPhoneNumber: '',
      varShowDialpad: false,
      callDuration: '',
      callStartTime: null,
      varSetInterval: null,
      varCalling: false,
      callTo: '',
      phoneNumberEdit: '',
      newNumber: false,
      editedNumber: '',
      editedComment: ''
    }
  }

  export default {
    name: 'PhoneCallDialer',
    data: () => { return getData() },
    beforeMount() {
      this.init()
    },
    props: {       
      rowData: {}
    },
    methods: {
      init() {
        let data = getData()

        for (let key in data) {
          this[key] = data[key]
        }

        this.updatePhoneNumbers()

        this.comment = _.get(this.rowData || {}, '_description.cati.vars.comment', '')
      },
      callSelected(phoneNumber) {
        this.selectedPhoneNumber = phoneNumber
        if (this.selectedPhoneNumber) {
          this.call(true)
        }
      },
      call(selected = false) {
        let callNumber = null

        if (selected === true) {
          callNumber = this.selectedPhoneNumber
        }
        else {
          if (this.inputPhoneNumber !== '') {
            callNumber = this.inputPhoneNumber == '' ? null : this.inputPhoneNumber
            // this.phoneNumbers.push({phoneNumber: this.inputPhoneNumber, comment: ''})
            // this.selectedPhoneNumber = this.inputPhoneNumber
            // this.inputPhoneNumber = ''
          }
          else {
            if (this.selectedPhoneNumber) {
              callNumber = this.selectedPhoneNumber
            }
          }
        }

        if (callNumber) {
          this.callDuration = "0:00"
          this.callStartTime = Date.now()

          this.varSetInterval = setInterval(() => {
            let seconds = 0

            if (this.callTo.length === 0) {
              this.callTo = this.$store.state.phoneCall.to
            }

            if (this.callStartTime) {
              seconds = Math.floor((Date.now() - this.callStartTime) / 1000)
              this.callDuration = Math.floor(seconds/60) + ':' + (seconds % 60).toString().padStart(2, '0')
            }    
          }, 1000)

          this.$store.state.phoneCall.call(callNumber, this.rowData)
        }                 
      },
      hangUp() {
        this.$store.state.phoneCall.hangUp()
      },
      record() {
        this.$store.state.phoneCall.recordCall()
      },
      stopRecord() {
        this.$store.state.phoneCall.stopRecord()
      },
      async updateComment() {
        _.set(this.rowData, '_description.cati.vars.comment', this.comment)

        this.editCommentDialog = false
        
        await this.$store.dispatch('updateSourceResultDescription', {
          id: this.rowData._id,
          action: 'updateComment',
          data: { comment: this.comment }
        })
      },
      showDialpad() {
        this.varShowDialpad = !this.varShowDialpad
      },
      updatePhoneNumbers() {
        this.phoneNumbers = _.get(this.rowData, '_description.personal_data.phoneNumbers') || []

        if (this.phoneNumbers.length > 0) {
          this.selectedPhoneNumber = this.phoneNumbers[0].phoneNumber
        } else {
          this.selectedPhoneNumber = null
        }
      },  
      digitAction(paramDigit) {
        if (this.$store.state.phoneCall.phoneState === 'calling') {
          if (paramDigit === '<') {

          }
          else {           
            this.callTo = this.callTo + 'd' + paramDigit
            this.$store.state.phoneCall.callObject.sendDigits(paramDigit)
          }          
        }
        else {
          if (paramDigit === '<') {
            if ( this.inputPhoneNumber.length > 1) {
              this.inputPhoneNumber = this.inputPhoneNumber.substring(0, this.inputPhoneNumber.length -1);
            }
            else if ( this.inputPhoneNumber.length === 1) {
              this.inputPhoneNumber = ''
            }
          }
          else {            
            this.inputPhoneNumber = this.inputPhoneNumber + paramDigit
          }
        }
      },
      editPhoneNumber(paramPhoneNumber) {
        this.phoneNumberDialog = true
        this.newNumber = false
        this.phoneNumberEdit = paramPhoneNumber
        const phoneObject = this.phoneNumbers.find( ({ phoneNumber }) => phoneNumber === this.phoneNumberEdit)
        this.editedNumber = phoneObject.phoneNumber
        this.editedComment = phoneObject.comment
      },
      addPhoneNumber() {
        this.phoneNumberDialog = true
        this.newNumber = true        
      },
      cancelNumberEdit() {
        this.phoneNumberDialog = false
        this.editedNumber = ''
        this.editedComment = ''
        this.newNumber = false 
        this.phoneNumberEdit = ''
      },
      async saveNumber() {
        if (this.newNumber===true) {
          this.phoneNumbers.push({phoneNumber: this.editedNumber, comment: this.editedComment})
        }
        else {
          const phoneObject = this.phoneNumbers.find( ({ phoneNumber }) => phoneNumber === this.phoneNumberEdit)
          phoneObject.phoneNumber = this.editedNumber
          phoneObject.comment = this.editedComment
        }
        _.set(this.rowData, '_description.personal_data.phoneNumbers', this.phoneNumbers)
        
        await this.$store.dispatch('updateSourceResultDescription', {
          id: this.rowData._id,
          action: 'updatePhoneNumbers',
          data: { phoneNumbers: this.phoneNumbers }
        })

        this.phoneNumberDialog = false
        this.phoneNumberEdit = ''
        this.editedNumber = ''
        this.editedComment = ''
        this.newNumber = false 
      },
      async deletePhoneNumber(phoneNumberObj) {
        let doDelete = await confirmDialog.open(
          this.$t('actions.confirm') + ': ' + this.$t('texts.DELETE_NUMBER'),
          '<div style="margin: 0 0 10px;">' + phoneNumberObj.phoneNumber + '</div><div style="color: #555;">' + phoneNumberObj.comment + '</div>',
          {
            agreeText: this.$t('actions.delete')
          }
        )

        if (!doDelete) {
          return
        }

        this.phoneNumbers = this.phoneNumbers.filter( ({ phoneNumber }) => phoneNumber !== phoneNumberObj.phoneNumber)

        _.set(this.rowData, '_description.personal_data.phoneNumbers', this.phoneNumbers)
        
        await this.$store.dispatch('updateSourceResultDescription', {
          id: this.rowData._id,
          action: 'updatePhoneNumbers',
          data: { phoneNumbers: this.phoneNumbers }
        })
     }

    }, //methods:
    computed: {
      contactStatus() {
        return _.get(this.rowData || {}, '_description.cati.vars.contactStatus', '')
      },
      showCall() {
        if (this.$store.state.phoneCall) {
          if (this.$store.state.phoneCall.phoneState === 'not_calling') {
            if (this.varCalling === true ) {
              this.varCalling = false
              this.callTo = ''
              clearInterval(this.varSetInterval)              
              setTimeout(() => {
                this.$store.state.phoneCall.callState = ''       
              }, 3000)            
            }
            return true
          }
        } else {return false}
      },
      showHangUp() {
        if (this.$store.state.phoneCall) {
          if (this.$store.state.phoneCall.phoneState === 'calling') {
            if (this.varCalling === false) {
              this.varCalling = true
            }
            return true
          }
        } else {return false}
      },
      showRecord() {
        if (this.$store.state.phoneCall) {
          return this.$store.state.phoneCall.phoneState === 'calling'
        } else {return false}
      },
      callState() {
        let varReturn = ''
        let varTranslated = ''

        for (let varState of this.$store.state.phoneCall.callState.split('/')) {
          switch (varState) {
            case 'Anrufen':
              varTranslated = this.$t('texts.CALL_STATE_CALLING')
              break
            case 'Freizeichen':
              varTranslated = this.$t('texts.CALL_STATE_RINGING')
              break
            case 'Verbunden':
              varTranslated = this.$t('texts.CALL_STATE_IN_PROGRESS')
              break
            case 'Aufgelegt':
              varTranslated = this.$t('texts.CALL_STATE_COMPLETED')
              break
            case 'Besetzt':
              varTranslated = this.$t('texts.CALL_STATE_BUSY')
              break
            case 'Anruf fehlgeschlagen':
              varTranslated = this.$t('texts.CALL_STATE_FAILED')
              break
            case 'Anruf':
              varTranslated = this.$t('texts.CALL_STATE_CALLING')
              break
            default:
              varTranslated = varState
          }

          if (varReturn.length > 0) {
            varReturn = varReturn + ', ' 
          }
          varReturn = varReturn + varTranslated
        }

        return varReturn
      }
    },  
    watch: {
      rowData() {
        this.init()
      }  
    }
  }
</script>

<style lang="scss" scoped>
  .phone-call-dialer {
    min-width: 370px;
    display: flex;
    flex-direction: column;
    flex: 1 1 0;
  }

  .call-box {
    flex: 0 0 auto;
    min-height: 0;
    padding: 15px;
    overflow: auto;
    border-bottom: 1px solid rgb(233, 233, 233);

    .button {
      margin: 0 0 0 8px;
    }
  }

  .source-phone-number {
    padding: 0 0;

    label {
      color: #fff !important;
      top: 8px !important;
    }

    input {
      min-width: 250px !important;
      color: #fff !important;
      -webkit-text-fill-color: #fff !important;
    }
    
    .md-field {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
      color: #fff;
      min-height: 32px;
    }

    .md-field.md-theme-toolbar:after {
      background-color: #fff;
    } 
  }

  .dialpad {
    padding: 0 0 20px;
  }

  .dialpad-row {
    display: flex;
    justify-content: center;
  }

  .dialpad-digit {
    margin: 2px;
    padding: 10px 30px;
    float: 1 1 auto;
    font-size: 2rem;
    cursor: pointer;
    text-align: center;
  }

  .sub {
    font-size: 0.8rem;
    color: grey;
  }

  .info-box {
    padding: 15px;

    .label {
      color: #aaa;
      font-weight: bold;
      padding: 2px 0 4px;  
    }
    
    .status {
      padding: 2px 0 4px;
    }  
  }

  .tool-box {
    flex: 1 1 0;
    overflow: auto;
  }

  .phone-numbers {
    padding: 0 0 10px;
  }

  // make tab items stretch to full height
  :deep {
    .data-entry-dialog  {
      overflow-y: visible;
    }

    .sv_body {
      padding: 0 !important;
      border: none !important;
    } 

    .v_window, .v-window__container, .v-window-item, .v-item-group {
      flex: 1 1 0;
      min-height: 0;
      display: flex;
    }

    .tabs-sidebar {
      display: flex;
      flex: 0 0 auto;
      box-shadow: none;
      border-left: 1px solid rgb(233, 233, 233);
    }
  }

</style>