export default {
  actions: {
    add_bookmark: "Add bookmark",
    add_color: "Add color",
    apply: "Apply",
    back: 'Back',
    bookmark_link: 'Bookmark link',
    cancel: "Cancel",
    call: "Call",
    change: "Change",
    close: "Close",
    change_color: "Change color",
    complete: "Complete",
    complete_interview: "Complete Interview",
    confirm: "Confirm",
    continue: 'Continue',
		delete: "Delete",
    duplicate: "Duplicate",
    edit: "Edit",
    export: "Export",
    hang_up: "Hang up",
    load_data: "Load data",
    log_out: "Log out",
    login: 'Login',
    move_down: 'Move down',
    move_up: 'Move up',    
    next: 'Next',
    ok: 'Ok',
    open: 'Open',
    previous: 'Previous',
    refresh: 'Refresh',
    refresh_data: "Refresh data",
    reload: "Reload",
		rename: "Rename",
    reset: "Reset",
    save: "Save",
    save_and_close: "Save and close",
    save_and_next: "Save and next",
    select_database: "Select database",
    send: "Send",
    show: "Show",
    start_analysis: "Start Analysis",
    stop_interview: "Stop Interview",
    to_start_page: "Go to start page",
    update: "Update"
	},
	errors: {
    APPLICATION_ERROR: 'Application Error',
    AUTHENTICATION_ERROR: 'Authentication Error',
    DATABASE_ERROR: 'Database Error',
    EMAIL_ERROR: 'Email Error',
    NO_ACCESS_RIGHTS: 'You do not have access rights for the requested data.',
    RECORD_NOT_AVAILABLE_ON_UPDATE: 'This record has been deleted in the meantime and the entries could not be saved.',
    RUNTIME_ERROR: 'Error',
    RUNTIME_INFO: 'Info',
    RUNTIME_WARNING: 'Warning',
    SESSION_EXPIRED: 'Your session expired. Please log in again.',
	SERVER_COMMUICATION_ERROR: 'Error',
    SERVER_COMMUICATION_INFO: 'Info',
    SERVER_COMMUICATION_WARNING: 'Warning',
    TIMEOUT_KEY: 'Dataset in progress',
    UNABLE_TO_CREATE_PDF: 'Unable to create PDF',
    UNEXPECTED_ERROR: 'Apologies for the inconvenience caused. The program has encountered an error. Our team has been notified and is actively working on resolving it.',
    VERSION_CHANGED_ON_UPDATE: 'This record has been changed in the meantime and the entries could not be saved. You may need to repeat your entries if necessary.'
	},
  headers: {
    edit: 'Edit',
    insert: 'Insert'
  },
  labels: {
    absolute: "Absolute (A)",
    all: "All",
    all_items: "All items",
    analysis: "Analysis",
    apply_filter: 'Apply filter',
    available: "Available",
    basis: "Basis (N)",
    clipboard: "Clipboard",
    charts: "Charts",
    data: "Data",
    datasets: "Datasets",
    decimal_separator: "Decimal separator",
    decimal_separator_dot: "dot",
    decimal_separator_comma: "comma",    
    description: "Description",
    examples_of_values: "Examples of values",
    f100: "F/100 (U)",
    f100Weighted: "F/100 weighted (G)",
    filetype: "File type",
    file: "File",
    filter: "Filter",
    group: "Group",
    note: "Note",
    options: "Options",
    original: "Original",
    row: "Row",
    search: "Search",
    select_view: "Select view...",
    selected: "Selected",
    syntax: "Syntax",
    table: "Table",
    tabulation: "Tabulation",
    total: "Total",
    values: "Values",
    variables: "Variables",
    view: "View"
  },
  progress: {
    exporting_datasets: 'Exporting datasets'
  },
  nav: {
    analysis: "Analysis",
    home: "Home"
  },
  notifications: {
    INVALID_FORMAT_DATE: "Invalid input: Valid date format is: YYYY-MM-DD",
    INVALID_FORMAT_NUMBER: "Invalid input: No number"
  },
  subnav: {
    business_terms: "Business terms and conditions",
    contact: "Contact",
    legal_notice: "Legal notice",
    privacy_policy: "Privacy policy",
    website: "TEMA-Q Website"
  },
  texts: {
    ADDITIONAL_FILTER___: "Additional filter from drilldown active",
    ANALYSIS_LINK: "Analysis link",
    APPLICATION_ERROR: 'Application Error',
    APPLY_CURRENT_FILTER: 'Apply current filter',
    APPLY_CURRENT_ANALYSIS: 'Apply current analysis',
    APPLY_FILTER: "Apply filter",
    AUDIO: 'Audio',
    AUDIO_PLAYBACK_SPEED: 'Playback speed',
    AUDIO_VOLUME: 'Volume',
    BACK: 'Back',
    BOOKMARK_NAME: 'Bookmark name',
    BOOKMARK_LINK: 'Bookmark link',
    BOOKMARKS: 'Bookmarks',
    CALL: 'Call',
    CALL_NUMBER: 'Call number',
    CALL_STATE_BUSY: 'Busy',
    CALL_STATE_CALLING: 'Calling',
    CALL_STATE_COMPLETED: 'Hung up',
    CALL_STATE_FAILED: 'Call failed',
    CALL_STATE_IN_PROGRESS: 'Connected',
    CALL_STATE_RINGING: 'Ringing',
    CHANGED_FILTERS: "Changed Filters",
    CHANGED_FILTERS_AND_SETTINGS: "Changed Filters and Settings",
    CHANGED_SETTINGS: "Changed Settings",
    CREATE_BOOKMARK: "Create Bookmark",
    CREATE_FOLDER: "Create Folder",
    CREATE_LINK: "Create Link",
    COLUMN_MENU_ALL_SAME_SIZE: "Use width of this column for all",
    COLUMN_MENU_AUTOSIZE_ALL: "Autosize All Columns",
    COLUMN_MENU_AUTOSIZE_CURRENT: "Autosize This Column",
    COLUMN_MENU_MINIMIZE_ALL: "Minimize All Columns",
    COLUMN_MENU_EXPAND_ALL: "Expand All Rows",
    COLUMN_MENU_COLLAPSE_ALL: "Collapse All Rows",
    COMMENT: "Comment",
    CONFIRM_DELETE_FOLDER: "Confirm delete folder",
    CONFIRM_DELETE_BOOKMARK: "Confirm delete bookmark",    
    COPY_LINK_TO_CLIPBOARD: "Copy Link to clipboard",
    DELETE_NUMBER: "Delete number",
    EDIT_BOOKMARK: "Edit bookmark",
    EDIT_COMMENT: "Edit comment",
    EDIT_FOLDER: "Edit folder",
    EDIT_NUMBER: "Edit number",
    email: {
      content: "Content",
      description: "Description",
      email_address: "Email Address",
      email_template: "Email Template",
      email_will_be_sent___: "Email will be sent to {count} recipients.",
      emails_sent: "Emails sent",
      finished: "Finished",
      no_preview: "no preview available",
      preview: "Preview",
      ready_to_send: "Ready to send",
      recipient: "Recipient",
      recipients_ready: "Recipients ({count} ready of {length})",
      send_emails: "Send Emails",
      send_test_email: "Send test email",
      sending: "Sending",
      status: "Status",
      subject: "Subject",
      text: "Text",
      to: "To",
      attachments: "Attachments"
    },
    ERROR_ACTIVATE_THIRD_PARTY_COOKIES: "Third party cookies must be activated.",
    ERROR_AUTH_FAILED: "Something went wrong. Please refresh and try again.",
    ERROR_ID: 'Error ID',
    ERROR_NO_SELECTION_FOR: "No selection for ",
    ERROR_TOO_FEW_ITEMS_SELECTED: "Too few items selected, minimum ",
    ERROR_TOO_MANY_ITEMS_SELECTED: "Too many items selected, maximum ",
    ERROR_WRONG_CREDENTIALS: "Wrong email or password.",
    EXPORT: "Export",
    EXPORT_ALL_DATA: "All Data",
    EXPORT_LINK: "Export link",
    EXPORT_LIMIT_NOTE: "ATTENTION: You are exporting more than 10,000 entries. You may continue but the export will automatically be limited to 10,000 entries.",
    EXPORT_ONLY_VISIBLE_DATA: "Only visible data",
    EXPORT_PAGE: "Page",
    EXPORT_SCOPE: "Scope",
    FILTER: 'Filter',
    FILTER_ARRAY_OF_STRINGS_NOTE_1: "Text values can be combined via commas",
    FILTER_ARRAY_OF_STRINGS_NOTE_2: "All results will be shown that match these values",
    FILTER_ARRAY_OF_STRINGS_PLACEHOLDER: "Enter text values here",
    FILTER_CHANGE_AND_RELOAD_MESSAGE: "You can change your filters and load your data.",
    FILTER_DATE_NOTE_1: "Start and end date of a rage must be split via '-'",
    FILTER_DATE_NOTE_2: "A range always includes the start and end date",
    FILTER_DATE_NOTE_3: "Multiple ranges can be combined via commas.",
    FILTER_DATE_NOTE_4: "Empty values can be selected/excluded with $null or $notnull",
    FILTER_DATE_NOTE_5: "Date syntax: YYYY/MM/DD or YYYYY/MM or YYYY",
    FILTER_DATE_NOTE_6: "Date variables: today, thisweek, thismonth, thisyear",
    FILTER_DATE_NOTE_7: "Optional offsets (number+unit): today(-4d), 2013/07/18(+2w)",    
    FILTER_DATE_PLACEHOLDER: "Enter date or range here",
    FILTER_DIALOG_INFO: {
      LIST_ENTRY_1_a: "Performs a full text search over the \"",
      LIST_ENTRY_1_b: "\" filter.",
      LIST_ENTRY_2: "A full text search might be slow on large datasets.",
      LIST_ENTRY_3: "Reducing the dataset with other filters improves the search performance."
    },
    FILTER_DIALOG_SYNTAX: {
      LIST_ENTRY_1: "And-operation as default: apple Jonagold",
      LIST_ENTRY_2: "Or-operation by prefix |: |apple Jonagold",
      LIST_ENTRY_3: "Negate an expression with !: !apple",
      LIST_ENTRY_4: "Match whole word and not only part of the word with ^: ^apple ",
      LIST_ENTRY_5: "Match multiple words with single quotation marks '': 'Granny Smith'",
      LIST_ENTRY_6: "Empty values can be selected/excluded with $null or $notnull"
    },
    FILTER_NUMBER_NOTE_1: "Intervals must be stplit via '-'",
    FILTER_NUMBER_NOTE_2: "Intervals always include the start and end value",
    FILTER_NUMBER_NOTE_3: "Intervals can be combined via commas",
    FILTER_NUMBER_NOTE_4: "Empty values can be selected/excluded with $null or $notnull",
    FILTER_NUMBER_PLACEHOLDER: "Enter value or interval here",
    FILTER_REFRESH_TO_APPLY_MESSAGE: "You can change your filters and refresh your data.",
		FILTER_SELECT_FILTER_MESSAGE: "Set filters to narrow down your data.",
    FILTER_STRING_PLACEHOLDER: "Enter free text here",
    FOLDER_NAME: "Folder Name",
    FOOTER_LEGAL_1: "WOENENN",
    FOOTER_LEGAL_2: "is an application of TEMA-Q. All rights reserved.",
    HOME_WELCOME: "Welcome to WOENENN",
    LAST_ANALYSIS: "Last Analysis",
    LOADING: "Loading data. Please wait.",
    LOGIN_SUCCESS: "Login successful!",
    NEW_NUMBER: "New number",
    NO_DATA_LOADED: "No data loaded",
    NO_DATA_FOUND: 'No matching datasets found',
    NO_ENTRIES: 'No Entries',
    NO_SAVED_BOOKMARKS: "No saved bookmarks",
    PHONE_NUMBERS: 'Phone Numbers',
    PUBLIC_BOOKMARKS: "Public bookmarks",
    PRIVATE_BOOKMARKS: "Private bookmarks",
    REFRESH: "Refresh",
    SAVE_WITH_FILTER: "Save with filter",
    SECTIONS: "Sections",
    SEND_EMAIL: "Send email",
    SETTINGS: "Settings",
    SETTINGS_TABULATION: "Settings Tabulation",
    SETTINGS_DATASETS: "Settings Datasets",
    SETTINGS_CHART: "Settings Chart",
    SESSION_EXPIRED: 'Session Expired',
    SHORT_URL: "Short URL",
    SHOW_GLOBAL_BOOKMARKS: "Show global bookmarks",
    SHOW_OTHER_SECTIONS: "Show other sections",
    STATUS: "Status",
    UPDATE_BOOKMARK: "Update bookmark",
    YOUR_LAST_ANALYSIS___: "Your last analysis has been saved.",
    YOUR_SESSION_HAS___: "Your Session has expired. You need to log in again to continue."
  },
	tooltips: {
    ADD_DATASET: 'Add dataset',
    ADD_NUMBER: "Add number",
    ADD_VARIABLE: 'Add variable',
    BOOKMARKS: 'Bookmarks',
    BOOKMARKS_PRIVATE: 'Private Bookmarks',
    CLOSE_SIDEBAR: 'Close sidebar',
    DELETE_ALL_FILTER: 'Delete all filter',
    EDIT_COMMENT: "Edit comment",
    FILTER: 'Filter',
    FORMAT_CODE: 'Format code',
    NAVIGATE_BACK: 'Navigate back',
    NAVIGATE_FORWARD: 'Navigate forward',
    OPEN_IN_NEW_WINDOW: "Open in new window",
    OPEN_SIDEBAR: 'Open sidebar',
    PUBLIC_BOOKMARKS: 'Public Bookmarks',
    RESET_FILTERS: "reset filters",
    SECTIONS: "Sections",
    SET_TO_DEFAULT: "Set to default",
    SETTINGS: 'Settings',
    SHOW_CODE: 'Show code',
    SHOW_PREVIEW: 'Show preview',
    START: 'Start',
    VIEW_DATASETS: 'Datasets view',
    VIEW_TABULATION: 'Tabulation view',
    VIEW_CHARTS: 'Charts view',
    
	},
  titles: {
    confirm: 'Confirm'
  }
}