<template>
    <v-app>
    <div id="app">
        <loading-view :loading="$store.state.loading" />

        <confirm-dialog v-for="(confirmDialog, index) in $store.state.confirmDialogs" v-bind:key="'confirm-dialog-' + index" :dialogObj="confirmDialog"></confirm-dialog>

        <data-entry v-for="(dataEntryDialog, index) in $store.state.dataEntryDialogs" v-bind:key="'data-entry-' + index" :dialogObj="dataEntryDialog"></data-entry>

        <surveyjs-creator v-for="(surveyCreatorDialog, index) in $store.state.surveyCreatorDialogs" v-bind:key="'surveyjs-creator-' + index" :dialogObj="surveyCreatorDialog"></surveyjs-creator>

        <md-app class="app" md-mode="fixed">
            <md-app-toolbar v-if="$store.state.ready" class="md-dense md-primary" :style="{ backgroundColor: appToolbarBackgroundColor }" md-elevation="3" md-theme="toolbar">
                <div class="md-toolbar-section-start" style="flex: 2;">
                    <router-link :to="{name: 'start'}" class="md-small-hide">
                        <div class="app-logo-wrapper">
                            <img v-if="logo" class="app-logo md-small-hide" :src="logo" alt="logo" />
                        </div>
                    </router-link>
                    <div v-if="$store.state.sources" style="margin: -4px 10px 0;">
                        <v-select
                            v-if="$store.state.sources.length > 1 || ($store.state.sources.length == 1 && $store.state.sources[0].roles && ($store.state.sources[0].roles.length > 1))"
                            v-model="sourceAndRoleSelection"
                            :items="sourceAndRoleSelections"
                            dense
                            dark
                            hide-details
                            single-line
                            color="#fff"
                            item-color="#fff"
                            style="width: 400px;"
                            @change="changeSource"
                        >
                            <template v-slot:selection="data">
                                <div v-if="data.item.value.type == 'source'">
                                    {{ data.item.value.source.label }}
                                </div>
                                <div v-if="data.item.value.type == 'role'">
                                    {{ data.item.value.source.label }} / {{ data.item.value.role.label }}
                                </div>
                            </template>
                            <template v-slot:item="data">
                                <div v-if="data.item.value.type == 'source'">
                                    {{ data.item.text }}
                                </div>
                                <div v-if="data.item.value.type == 'role'" style="padding-left: 20px;">
                                    {{ data.item.text }}
                                </div>
                            </template>
                        </v-select>

                        <span class="md-body-2" v-if="($store.state.sources.length == 1 && (!$store.state.sources[0].roles || ($store.state.sources[0].roles.length == 1))) && $store.state.source">{{ $store.state.source.label }}</span>

                        <div class="text-smaller" style="white-space: nowrap; margin: 3px 0 0;" v-if="$store.state.view">{{ $store.state.view.label }}</div>
                    </div>
                    
                    <div v-if="$router.currentRoute.name !== 'filter'">
                        <div v-if="$store.state.sortedResultMeta" class="text-smaller mr-1">
                            <div class="text-body-2 nowrap">N: {{ $store.state.sortedResultMeta.n }}</div>
                            <div class="text-body-2 nowrap" v-if="$store.state.view.verbatim">DS: {{ $store.state.sortedResultMeta.ds }}</div>
                        </div>
                    </div>
                </div>

                <div class="md-toolbar-section-end" style="flex: 1;">    
                    <md-tabs :md-active-tab="$store.state.activeTab" @md-changed="changeTab" class="mr-2 md-transparent">
                        <template slot="md-tab" slot-scope="{ tab }">
                            <md-icon class="mb-1">{{ tab.icon }}</md-icon>
                            {{ tab.label }} <i class="badge" v-if="tab.data.badge">{{ tab.data.badge }}</i>
                        </template>
                        <md-tab 
                                id="sourceStart"
                                :md-label="$t('nav.home')"
                                md-icon="home"   
                                style="color: #fff;"                         
                        />
                        <md-tab 
                                id="analysis"
                                :md-label="$t('nav.analysis')"
                                md-icon="playlist_add_check"
                                :md-disabled="$store.getters.isEmptySourceViews"                      
                        />
                    </md-tabs>

                    <md-menu md-size="small">
                        <md-button class="md-icon-button" md-menu-trigger>
                            <md-icon class="account-icon">account_circle</md-icon>
                        </md-button>
                        <md-menu-content>
                            <md-subheader>{{ $store.state.user.email }}</md-subheader>
                            <md-menu-item @click="logout">
                                <md-icon>exit_to_app</md-icon>
                                <span class="md-list-item-text">{{ $t('actions.log_out') }}</span>
                            </md-menu-item>
                        </md-menu-content>
                    </md-menu>
                </div>
            </md-app-toolbar>

            <md-app-content id="content" v-if="!$auth.loading && $auth.isAuthenticated">
                <!--<keep-alive>-->
                <router-view />
                <!--</keep-alive>-->
            </md-app-content>
        </md-app>

        <footer class="md-layout md-alignment-center-space-between">
            <div class="md-layout-item md-size-60 md-small-size-40">
                <span style="margin-right: 8px;">
                    {{ $t('texts.FOOTER_LEGAL_1') }} ({{release}})<span class="md-medium-hide"> {{ $t('texts.FOOTER_LEGAL_2') }}</span>
                </span>
                <span style="white-space: nowrap;">
                    <a class="ml-1" href="mailto:support@tema-q.de" target="_blank" style="margin-left: 0px !important;">
                    <md-icon class="mr-1">mail_outline</md-icon>{{ $t('subnav.contact') }}</a>
                    <a class="ml-1" href="https://tema-q.de/impressum/" target="_blank">
                    <md-icon class="mr-1">link</md-icon>{{ $t('subnav.legal_notice') }}</a>
                    <a class="ml-1" href="https://tema-q.de/datenschutzhinweise/" target="_blank">
                    <md-icon class="mr-1">link</md-icon>{{ $t('subnav.privacy_policy') }}</a>
                </span>
            </div>
            <div class="md-layout-item md-size-40 md-small-size-60 ta-r">
                <div class="d-ib lang-select">
                    <md-field >
                        <md-select
                            md-dense
                            v-model="locale"
                            @md-selected="setLanguage"
                            :class="'input-' + locale"
                        >
                            <md-option
                                md-dense
                                class="lang-option option-de"
                                value="de"
                            >
                                Deutsch
                            </md-option>
                            <md-option
                                class="lang-option option-en"
                                value="en"
                            >
                                English
                            </md-option>
                        </md-select>
                    </md-field>
                </div>
                <div class="d-ib">
                    &copy; {{ year }} TEMA-Q GmbH
                </div>
            </div>
        </footer>

        <error-message></error-message>

        <md-snackbar md-position="center" :md-duration="20000"
                     :md-active.sync="$store.state.showNotification" md-persistent>
            <span>{{ $store.state.notificationMessage }}</span>
            <md-button class="md-primary" @click="$store.state.showNotification = false">{{ $t('actions.close') }}</md-button>
        </md-snackbar>

        <v-overlay v-if="$store.state.isProgress" :model-value="$store.state.isProgress" class="align-center justify-center">
            <v-card>
                <v-card-title>
                    {{ $store.state.progress.title }}
                </v-card-title>
                <v-card-text>
                    <v-progress-linear
                        :value="$store.state.progress.percent"
                    ></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-overlay>
    </div>
    </v-app>
</template>

<script>
import _ from 'lodash'
import { getLocale, setLocale } from '@/i18n'

import SourceModel from '@/models/source'

export default {
    name: 'App',
    data: () => ({
        locale: getLocale(),
        ready: false,
        source: null,
        sourceAndRoleSelection: null,
        sourceAndRoleSelections: null
    }),
    watch: {
        '$store.state.source': function() {
            this.setSourceAndRoleSelection()
        },
        '$store.state.roleName': function() {
            this.setSourceAndRoleSelection()
        },
        '$store.state.ready': function(ready) {
            if (!ready) {
                this.ready = false
                return
            }

            let sources = this.$store.state.sources
            let sourceAndRoleSelections = []

            if (sources) {
                sources.forEach(source => {
                    let roles = source.roles

                    if (roles && source.roles.length > 0) {
                        if (roles.length > 1) {
                            sourceAndRoleSelections.push({
                                header: source.label,

                                value: {
                                    type: 'sourceHeading'
                                }
                            })
                            roles.forEach(role => {
                                sourceAndRoleSelections.push({
                                    text: role.label,
                                    value: {
                                        type: 'role',
                                        source: source,
                                        role: role
                                    }
                                })
                            })
                        } else {
                            sourceAndRoleSelections.push({
                                text: source.label,
                                value: {
                                    type: 'source',
                                    source: source,
                                    role: roles[0]
                                }
                            })
                        }
                    } else {
                        sourceAndRoleSelections.push({
                            text: source.label,
                            value: {
                                type: 'source',
                                source: source,
                            }
                        })
                    }
                })
            }
            
            this.sourceAndRoleSelections = sourceAndRoleSelections

            this.setSourceAndRoleSelection()

            this.ready = true
        }
    },
    computed: {
        release() {
            return this.$store.state.startupData ? 'v' + this.$store.state.startupData.release : ''
        },
        appToolbarBackgroundColor() {
            return _.get(this.$store.state.view || {}, 'settings.topbar.color')
        },
        year() {
            return new Date().getFullYear()
        },
        filterBadge() {
            let i = 0
            let query = this.$store.state.query
            _.each(query, function (v) {
                if (typeof v !== 'undefined' && v !== null) { i++ }
            })
            return i
        },
        logo() {
            let source = this.$store.state.source
            let page = source && source.page

            if (source) {
                let logo = (page && page.logo) ? page.logo : 'default.png'
                return 'https://dsukjyvr3uysd.cloudfront.net/images/logos/' + logo
            } else {
                return null
            }
        }
    },
    methods: {
        async changeSource(sourceAndRoleSelection) {
            await SourceModel.routeTo(sourceAndRoleSelection.source, sourceAndRoleSelection.role)
        },
        changeTab(tabId) {
            let state = this.$store.state
            let currentRouteName = this.$router.currentRoute.name

            if (currentRouteName && currentRouteName !== tabId) {
                switch (tabId) {
                    case 'sourceStart':
                    case 'sections':
                        this.$router.push({ name: tabId })
                        break
                    case 'filter':
                    case 'analysis':
                        if (state.source) {
                            if (state.view) {
                                this.$router.push({ name: 'analysis', params: { sourceName: state.source.name, viewName: state.view.name, roleName: state.roleName } })
                            } else {
                                this.$router.push({ name: 'analysisRedirect2', params: { sourceName: state.source.name, roleName: state.roleName } })
                            }
                        }
                }
            }
        },
        /*logout() {
            this.$auth.logout({
                returnTo: window.location.origin
            })
        },*/
        async logout() {
            // Logout from Auth0
            await this.$auth.logout({
                logoutParams: {
                    returnTo: window.location.origin
                }
            })
        },
        setLanguage(locale) {
            this.locale = locale
            setLocale(locale)
        },
        setSourceAndRoleSelection() {
            if (!this.sourceAndRoleSelections) {
                return
            }

            let source = this.$store.state.source
            let roleName = this.$store.state.roleName

            if (!source) {
                return
            }

            let sourceAndRoleSelection = this.sourceAndRoleSelections.find(sourceAndRoleSelection => {
                let value = sourceAndRoleSelection.value

                return (value.type == 'role' || value.type == 'source')  && ((source.name == value.source.name) && (!roleName || roleName == (value.role && value.role.name)))
            })

            this.sourceAndRoleSelection = sourceAndRoleSelection
        }
    },
    beforeMount() {

    },
    beforeRouteLeave(to, from, next) {
        this.isLoading = true
        next()
    }
}
</script>

<style lang="scss" scoped>
    #app {
        display: flex;
        flex-direction: column;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        .md-app {
            flex: 1 0 0;
            font-family: 'Roboto', sans-serif;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }

        .app-logo {
            height: 48px;
            width: 129px;
        }
        
        .app-logo-wrapper {
            height: 48px;
            width: 129px;
            margin-right: 16px;
        }
    }

    #content {
        padding: 0;
        display: flex;
        flex-direction: column;
        border-left: none;
        border-right: none;
        align-items: center;
        background: #fafafa;
    }

    footer {
        flex: 0 0 40px;
        background: #fff;
        padding: 0 16px;
        z-index: 3;
        font-size: 13px;
        color: #333;
        border-top: 1px solid #ddd;
    }

    .badge {
        width: 19px;
        height: 19px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 8px;
        right: 8px;
        background: $red;
        border-radius: 100%;
        color: #fff;
        font-size: 10px;
        font-style: normal;
        font-weight: 600;
        letter-spacing: -.05em;
    }

    a:hover {
        text-decoration: none;
    }
</style>

<style lang="scss">
    @import "~vue-material/dist/theme/engine";

    @import "~js-datepicker/dist/datepicker.min.css";
    
    //@at-root@import "~survey-vue/survey";

    @import '~survey-core/survey.min.css';
    //@import '~survey-knockout/modern.min.css';
    @import "~survey-core/defaultV2.min.css";
    @import "~survey-creator-core/survey-creator-core.min.css";

    @include md-register-theme("default", (
        primary: $primary,
        accent: $accent,
        theme: light
    ));
    @include md-register-theme("toolbar", (
        primary: $grey-dark,
        accent: $primary,
        theme: light
    ));
    @import "~vue-material/dist/theme/all";

    html, body, #app .md-app-scroller, #content {
        background-color: $background;
    }

    .nowrap {
        white-space: nowrap;
    }

    .md-overlay {
        background: rgba(255,255,255,0.8) !important;
        top: 72px;
    }

    // All drop downs need a higher index
    // Otherwise they appear behind the dialog
       .md-menu-content {
           z-index: 9999 !important;
       }

    // Make dialog scrollable and bigger
       .md-dialog {
           min-width: 480px !important;
           max-height: 95% !important;
       }
    body .md-dialog-content {
        padding: 24px;
        //max-height: 500px;
        overflow-y: auto;
    }

    .md-toolbar {
        .md-title {
           // Remove space from title and tabs in app toolbar
             margin-left: 0;
        }

        .md-tabs {
            // Remove space from title and tabs in app toolbar
            padding-left: 0;

            .md-icon {
                color: #fff !important;
            }

            .md-tabs-indicator {
                background-color: #fff !important;
            }
        }
    }

    // Change color and height of tabs indicator in toolbar
       .md-tabs.md-theme-toolbar.md-primary .md-tabs-indicator {
           height: 4px;
           background-color: $red-light;
       // border-top-left-radius: 4px;
       // border-top-right-radius: 4px;
       }

    .md-table.md-theme-default .md-table-head {
        color: black;
    }

    .md-table.md-theme-default .md-sortable:hover, .md-table.md-theme-default .md-sortable.md-sorted {
        color: $primary;
    }

    .md-field.md-theme-default.md-focused .md-input, .md-field.md-theme-default.md-focused .md-textarea, .md-field.md-theme-default.md-has-value .md-input, .md-field.md-theme-default.md-has-value .md-textarea {
        font-weight: 500;
    }

    .md-button {
        font-weight: 600;
    }

    .md-button:before {
        // This is a workaround for a Chrome bug where buttons in a dialog are not showing (2020-07-29)
        // TODO: Remove when Bug solved in Chrome
        will-change: auto !important;
    }

    .md-toolbar.md-theme-toolbar.md-primary .md-icon {
        color: #fff;
    }

    .md-table.md-theme-default .md-table-row:hover:not(.md-header-row) .md-table-cell {

        background: $blue-light;

    }

    .md-table-head-label {
        text-transform: uppercase;
    }

    .md-field.md-focused label,
    .md-field.md-has-value label {
        text-transform: uppercase;
        //color: $primary;
        font-weight: 600;
    }

    // List item text to primary color, e.g. on Start.vue
    .md-list.md-theme-default .md-list-item-container {
           color: $primary;
       }

    // Empty state icon with primary color
       .md-empty-state.md-theme-default .md-empty-state-icon.md-icon-font {
           color: $primary;
       }

    header {
        background: #f2f2f2;
        padding: 24px 16px 16px 16px;
        //margin: -16px;
        margin-bottom: 0;
        border-bottom: 1px solid #ddd;
    
        h1 {
            margin: 16px 0;
            padding: 0;
            font-size: 24px;
            font-weight: 500;
            color: #555;
        }
        .md-field {
            margin: 0;
        }
        &.no-border {
            border-bottom: 0;
        }
    }

    thead {
        background: #f2f2f2;
    }
    
    // Language select
       .lang-select {
           margin-right: 8px;
    .md-field {
        min-height: auto !important;
        margin: 0 10px 0 0 !important;
        padding: 0 !important;
    }
    input {
        font-size: 14px !important;
        padding-left: 33px !important;
    }
    .md-select {
        background-position: 5px 10px;
        background-repeat: no-repeat;
    }
    }
    .lang-option {
        background-position: 18px 18px;
        background-repeat: no-repeat;
    .md-list-item-text {
        padding-left: 35px;
    }
    }
    .input-de,
    .option-de {
        background-image: url('./assets/flags/de.png');
    }
    .input-en,
    .option-en {
        background-image: url('./assets/flags/en.png');
    }

    .md-icon.account-icon {
      width: 32px;
      min-width: 32px;
      height: 32px;
      font-size: 32px!important;
    }


    /* Helper classes */
    .ta-r { text-align: right !important; }
    .ta-c { text-align: center !important; }
    .pos-r { position: relative !important; }
    .pos-a { position: absolute !important; }
    .pos-f { position: fixed !important; }
    .d-ib { display: inline-block !important; }
    .mt-0 { margin-top: 0 !important; }
    .mb-0 { margin-bottom: 0 !important; }
    .ml-0 { margin-left: 0 !important; }
    .mr-0 { margin-right: 0 !important; }
    .mt-1 { margin-top: 8px !important; }
    .mb-1 { margin-bottom: 8px !important; }
    .ml-1 { margin-left: 8px !important; }
    .mr-1 { margin-right: 8px !important; }
    .mt-2 { margin-top: 16px !important; }
    .mb-2 { margin-bottom: 16px !important; }
    .ml-2 { margin-left: 16px !important; }
    .mr-2 { margin-right: 16px !important; }
    .mt-3 { margin-top: 24px !important; }
    .mb-3 { margin-bottom: 24px !important; }
    .ml-3 { margin-left: 24px !important; }
    .mr-3 { margin-right: 24px !important; }
    .mt-4 { margin-top: 32px !important; }
    .mb-4 { margin-bottom: 32px !important; }
    .ml-4 { margin-left: 32px !important; }
    .mr-4 { margin-right: 32px !important; }
    .mt-5 { margin-top: 48px !important; }
    .mb-5 { margin-bottom: 48px !important; }
    .ml-4 { margin-left: 48px !important; }
    .mr-4 { margin-right: 48px !important; }
    .d-ib { display: inline-block !important; }
    .text-smaller { font-size: 0.94em; }
    .text-italic { font-style: italic; }
    .primary-color { color: $primary; }
    .red-light-color { color: $red-light; }
    .muted { opacity: 0.7; }


    /*.source-select {
        padding: 0 0;

        label {
            color: #fff !important;
            top: 8px !important;
        }

        input {
            min-width: 250px !important;
            color: #fff !important;
            -webkit-text-fill-color: #fff !important;
        }
        
        .md-field {
            padding-top: 0 !important;
            padding-bottom: 0 !important;
            margin-top: 0 !important;
            margin-bottom: 0 !important;
            color: #fff;
            min-height: 32px;
        }

        .md-field.md-theme-toolbar:after {
            background-color: #fff;
        }
    }*/

    .source-header {
        font-size: 16px;
        margin: 10px 16px 0;
        padding: 3px 0;
        border-bottom: 1px solid rgba(0,0,0,0.42);
        font-weight: 500;
    }

    // ---- START SureyJs ----
    
    // Fix min-width set to 300px destroys layout for smaller fields
    .sv_qstn {
        min-width: 0px !important;
    }

    .sv_row {
        min-width: 0px !important;
    }
    // ---- END SureyJs ----
</style>