<template>
<div class="chart-container">
   <highcharts ref="chart" class="chart" :options="chartOptions"></highcharts> 
</div>
</template>

<script>

import api from '@/services/api'
import urlUtils from '@/services/url'
import { getViewData } from '@/services/viewData';
import evalExpression from '@/services/boolExEval'

import { tabulationResultToChartOptions } from '@/services/highchart/util'  

export default {
  name: 'InputTree',
  props: {
    linkOrLinkId: {
      type: String,
      default: 'http://localhost:8080/5fa2d2e1ad921e3bdcccfa56' //'5fa038af9ca1c43578190d84'
    },
  },
  data() {
    return {
      chartOptions: {}
    }
  },
  methods: {
    async openLink(linkId) {
      let link = (await api.call('getLink', { linkId: linkId })).data;
      let path = link.path;
      let urlData = urlUtils.decomposeUrl(path);
      
      let analysisSettings = urlData.params.analysisSettings;
      let viewName = urlData.viewName;
      let sourceName = urlData.sourceName;
      let roleName = urlData.roleName;

      let viewData = await getViewData(sourceName, viewName, roleName);
      let analysisSettingsModel = viewData.analysisSettingsModel;
      let variables = viewData.variables;
          
      let filter = {}; //ToDo
      
      let ratios = analysisSettings.ratios;
      
      for (let i = 0; i < ratios.length; i++) {
        let ratio = ratios[i];
        ratio.id = ratio.name + '_' + (i + 1);
      }

      // Generate query from analysis settings
      let queryData = {
        analysis: 'tabulation',
        ratios: ratios
      };
      for (let settingsModelEntry of analysisSettingsModel) {
        let settingsKey = settingsModelEntry.name;
        if (evalExpression(settingsModelEntry.filter, {view: 'tabulation', settings: analysisSettings})) {
          queryData[settingsKey] = queryData[settingsKey] || analysisSettings[settingsKey];
        }
      }
      
      let data = {
        querydata: queryData
      };
      if (filter != null) {
        data.filter = filter;
      }

      let settingsChartOptions = analysisSettings.chart_options;
   
      api.call('getSourceViewPivotResults', {
          sourceName: sourceName,
          viewName: viewName,
          data: data,
        }, queryResult => {
          // convert query data and query result to chart model
          settingsChartOptions = settingsChartOptions || {};
          let options = tabulationResultToChartOptions(queryResult, queryData, analysisSettingsModel, variables, settingsChartOptions);  
          this.chartOptions = options;
        });
    },       
  },
  mounted() {
    let linkOrLinkId = this.linkOrLinkId;
    if (linkOrLinkId != null) {
      let lastSlashIndex = linkOrLinkId.lastIndexOf('/');
      let linkId;
      if (lastSlashIndex !== -1) {
        linkId = linkOrLinkId.substr(lastSlashIndex + 1)
      } else {
        linkId = linkOrLinkId;
      }
      this.openLink(linkId);
    }
  }
}
</script>

<style lang="scss" scoped>
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
</style>
